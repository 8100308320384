import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import {
  GitHub,
  Twitter,
  Instagram,
  Facebook,
  YouTube,
} from '@mui/icons-material';

const Footer = () => {
  return (
    <Container
      sx={{
        mt: 5,
        p: 2,
        backgroundColor: 'black',
        position: 'relative',
        bottom: 0,
      }}
      maxWidth='false'
    >
      <Grid container>
        <Grid xs={12} md={3}>
          <Stack>
            <Typography fontSize={24} fontFamily='Poppins' align='center'>
              MovieDocs
            </Typography>
            <Typography
              variant='subtitle1'
              fontFamily='Poppins'
              sx={{ width: '100%' }}
              align='center'
              fontSize={14}
            >
              Best place to discover more about your favorite movie.
            </Typography>
          </Stack>
        </Grid>

        <Grid xs={0} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack ml='30%'>
            <Typography fontFamily='Outfit' fontSize={15}>
              <Link to='/Trending?page=1' style={{ color: 'white' }}>
                Trending
              </Link>
            </Typography>
            <Typography fontFamily='Outfit' fontSize={15}>
              <Link to='/playing?page=1' style={{ color: 'white' }}>
                In Theather
              </Link>
            </Typography>
            <Typography fontFamily='Outfit' fontSize={15}>
              <Link to='/upcoming?page=1' style={{ color: 'white' }}>
                Coming Soon
              </Link>
            </Typography>
          </Stack>
        </Grid>

        <Grid xs={12} md={4}>
          <Stack alignItems={{ xs: 'center', md: 'start' }}>
            <Typography fontFamily='Outfit' fontSize={15}>
              Find us Here
            </Typography>
            <Stack direction='row' spacing={1}>
              <Link style={{ color: 'white' }}>
                <Twitter />
              </Link>
              <Link style={{ color: 'white' }}>
                <Facebook />
              </Link>
              <Link style={{ color: 'white' }}>
                <GitHub />
              </Link>
              <Link style={{ color: 'white' }}>
                <Instagram />
              </Link>
              <Link style={{ color: 'white' }}>
                <YouTube />
              </Link>
            </Stack>
          </Stack>
        </Grid>

        <Grid xs={12} md={1}>
          <Container
            sx={{
              justifyContent: 'end',
              mt: 3,
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <img
              src='https://www.themoviedb.org/assets/2/v4/logos/v2/blue_square_2-d537fb228cf3ded904ef09b136fe3fec72548ebc1fea3fbbd1ad9e36364db38b.svg'
              alt='TMDB'
              style={{ width: '50px' }}
            />
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
