import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  Divider,
  Paper,
  Typography,
  styled,
  Stack,
  Snackbar,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MuiAlert from '@mui/material/Alert';

import { submitReviews, showReviews, showAll } from '../Utils/fetchData';
import { useNavigate } from 'react-router-dom';

const ReviewBox = ({ id, rating, user: { user } }) => {
  const Navigate = useNavigate();
  const [review, setReview] = useState('');
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(false);
  const [buttonText, setButtonText] = useState('Submit');
  const [userReview, setUserReview] = useState('');
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState([]);
  const [score, setScore] = useState([]);
  const [imagePath, setImagePath] = useState([]);

  const ReviewName = styled(Typography)({
    color: 'white',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  });

  const ReviewText = styled(Typography)({
    color: 'white',
    fontFamily: 'Open Sans',
  });

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const handleReview = async () => {
    if (review === '') return;
    try {
      const res = await submitReviews({ message: review }, id);
      setOpen(true);
      setError(false);
      fetchData();
      // console.log(res);
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const fetchData = async () => {
    try {
      const {
        data: { reviews },
      } = await showReviews(id);
      setReviews(reviews.slice(0, 2));

      if (reviews.length > 0) {
        fetchAll();
        const userReview = reviews.find(
          (review) => review.user_id === user?.id
        );
        if (userReview) {
          setReview(userReview.message);
          setUserReview(userReview.message);
          setButtonText('Edit');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAll = async () => {
    setUsername([]);
    setScore([]);
    try {
      const {
        // data: { reviews },
        data: { reviews },
      } = await showAll(id, 1, 'popularity');
      reviews.forEach((review) => {
        setUsername((prev) => [...prev, review.User.username]);
        setScore((prev) => [...prev, review.User.Ratings[0].value]);
        setImagePath((prev) => [...prev, review.User.image_path]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Paper
        sx={{
          backgroundColor: 'inherit',
          overflow: 'hidden',
          width: { xs: '95%', md: '100%' },
          height: '500px',
          color: 'white',
          ml: { xs: 0, md: 3 },
          p: { xs: 1, md: 3 },
          boxShadow: 'none',
        }}
      >
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            sx={{ width: '100%' }}
          >
            Reviews Saved!
          </Alert>
        </Snackbar>

        <Stack>
          <Typography
            sx={{
              color: 'white',
              fontFamily: 'Outfit',
              fontSize: '32px',
              fontWeight: 'bold',
            }}
          >
            Reviews
          </Typography>

          <Divider
            orientation='horizontal'
            flexItem
            color='white'
            light
            variant='fullWidth'
          />
          <Paper
            component='form'
            sx={{
              mt: 2,
              width: '100%',
              bgcolor: 'transparent',
              position: 'relative',
              boxShadow: 'none',
            }}
          >
            <textarea
              spellCheck='false'
              className='review'
              type='text'
              placeholder={
                user
                  ? 'Write your reviews here'
                  : 'Please Login to write reviews'
              }
              value={review}
              onChange={(e) => {
                setReview(e.target.value);
              }}
              onFocus={() => {
                if (!user) return Navigate('/login');
              }}
            />
            {error === true && (
              <Typography color='red' fontFamily='Open Sans'>
                Please give a rating before submiting review
              </Typography>
            )}

            <Button
              disabled={userReview === review ? true : false}
              onClick={(e) => {
                if (rating === 0) return setError(true);
                handleReview();
              }}
              variant='outlined'
              sx={{
                right: 0,
                position: 'absolute',
                mt: 2,
                borderRadius: 5,
                '&.MuiButtonBase-root:disabled': {
                  color: 'gray',
                  borderColor: 'gray',
                },
              }}
            >
              {buttonText}
            </Button>

            <Divider
              orientation='horizontal'
              flexItem
              color='gray'
              light
              variant='fullWidth'
            />
          </Paper>

          <Stack mt={10} spacing={0}>
            {reviews.map((each, index) => (
              <Grid
                container
                key={each.id}
                sx={{
                  backgroundColor: '#2C3E50',
                  borderRadius: 2,
                  mt: 2,
                }}
              >
                <Grid xs={1} justifyContent='center' display='flex'>
                  <Avatar sx={{ width: 32, height: 32 }} src={`http://localhost:8080/images/${imagePath[index]}`}>
                    {username[index]?.split('')[0]}
                  </Avatar>
                </Grid>
                <Grid xs={11}>
                  <Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      mb={1}
                      divider={
                        <Divider
                          orientation='vertical'
                          flexItem
                          color='gray'
                          light
                          variant='medium'
                        />
                      }
                    >
                      <ReviewName>{username[index]}</ReviewName>
                      <Typography
                        fontSize='16px'
                        sx={{
                          ml: 2,
                          color:
                            score[index] < 4
                              ? 'red'
                              : score > 7
                              ? 'green'
                              : 'yellow',
                          fontFamily: 'Open Sans',
                          fontWeight: 'bold',
                        }}
                      >
                        {score[index]}
                      </Typography>
                    </Stack>
                    <ReviewText>
                      {each.message.length > 255
                        ? each.message.slice(0, 255) + '...'
                        : each.message}
                    </ReviewText>
                  </Stack>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Stack>
      </Paper>
      <Button
        disabled={reviews.length < 1 && true}
        variant='outlined'
        sx={{ ml: 5 }}
        onClick={() => Navigate(`/review/${id}`)}
      >
        Show all reviews
      </Button>
    </>
  );
};

export default ReviewBox;
