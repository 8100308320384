import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Stack } from '@mui/system';
import { Button, FormHelperText, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { login } from '../Utils/fetchUser';
import { forgotPass } from '../Utils/fetchData';

export default function LoginBox() {
  const Navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const [falseInfo, setFalseInfo] = React.useState(false);
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.username === '' || values.password === '') {
      setError(true);
      return;
    }
    try {
      const res = await login(values);
      console.log(res.data);
      localStorage.setItem('user', JSON.stringify(res.data));
      Navigate('/');
      window.location.reload();
    } catch (error) {
      setError(false);
      setFalseInfo(true);
      console.log(error);
    }
  };

  // const handleForgot = async (e) => {
  //   try {
  //     const res = await forgotPass();
  //     console.log(res);
  //     if (res.status === 200){
  //       console.log('ok')
  //     }
  //   } catch (error) {
  //     setError(false);
  //     setFalseInfo(true);
  //     console.log(error);
  //   }
  // };

  return (
    // <form>
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Stack
        direction='column'
        sx={{
          height: { xs: '100vh', md: '60vh' },
          width: { xs: '100vw', md: '25vw' },
          backgroundColor: '#EFEFEF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          border: '1px solid black',
          boxShadow: 5,
        }}
      >
        <Typography
          color='black'
          sx={{
            backgroundColor: '#369BFB',
            width: '100%',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            fontSize: '24px',
            fontFamily: 'Poppins',
            mb: 3,
            top: 0,
            position: 'absolute',
          }}
        >
          Sign In
        </Typography>
        <form onSubmit={(e) => handleSubmit(e)}>
          <TextField
            autoFocus
            label='Username'
            id='username'
            sx={{ m: 1, width: '30ch' }}
            required
            value={values.username}
            onChange={(e) => setValues({ ...values, username: e.target.value })}
            helperText={error ? 'Username cannot be empty' : ' '}
          />
        </form>
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl sx={{ width: '30ch' }} variant='outlined'>
            <InputLabel htmlFor='outlined-adornment-password'>
              Password
            </InputLabel>

            <OutlinedInput
              id='password'
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Password'
            />
            <FormHelperText>
              {error
                ? 'Password cannot be empty'
                : falseInfo
                ? 'Username or Password Wrong'
                : ''}
            </FormHelperText>
          </FormControl>
        </form>

        <Button
          variant='contained'
          onClick={handleSubmit}
          sx={{ mt: 1, mb: 3 }}
        >
          Sign in
        </Button>

        <Typography
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            bottom: { xs: '10px', md: '20px' },
            textAlign: 'center'
          }}
        >
          <Link
            to='/forgot-pass'
            style={{ color: '#2587BB', fontFamily: 'Poppins' }}
          >
            Forgot Password
          </Link>
          <br />
          <Link
            to='/register'
            style={{ color: '#2587BB', fontFamily: 'Poppins' }}
          >
            Register here
          </Link>
        </Typography>
      </Stack>
    </Box>
    // </form>
  );
}
