import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import Slider from 'react-slick';

import { fetchMovieData } from '../Utils/fetchMovie';

const key = process.env.REACT_APP_API_KEY;

const Cast = ({ id }) => {
  const [casts, setCasts] = useState([]);

  const fetchCast = async () => {
    try {
      const credits = await fetchMovieData(
        `movie/${id}/credits?api_key=${key}&language=en-US`
      );
      setCasts(credits.cast);
    } catch (error) {
      console.log(error);
    }
  };

  const settings = {
    infinite: false,
    slidesToShow: 5,
    // slidesToScroll: 5,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    swipeToSlide: true,
  };

  useEffect(() => {
    fetchCast();
  }, []);

  if (!casts.length) return null;

  return (
    <Paper
      sx={{
        backgroundColor: 'inherit',
        width: '85%',
        height: '100px',
        ml: {xs: 1, md: 5},
        pt: 3,
        pl: 1,
        boxShadow: 'none',
      }}
    >
      <Stack>
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'Outfit',
            fontSize: '32px',
            fontWeight: 'bold',
          }}
        >
          Cast
        </Typography>
        <Divider
          orientation='horizontal'
          flexItem
          color='white'
          light
          variant='fullWidth'
        />

        <Slider {...settings}>
          {casts.map((cast) => (
            <Stack direction='column' key={cast.id}>
              <Container maxWidth='false' sx={{ display: 'flex', mt: 2 }}>
                <Avatar
                  sx={{ width: 80, height: 80 }}
                  src={
                    cast.profile_path &&
                    `https://image.tmdb.org/t/p/original/${cast.profile_path}`
                  }
                />
                <Stack ml={2} mt={1}>
                  <Typography color='white' fontFamily='Poppins' fontSize={20}>
                    {cast.name}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    color='white'
                    fontFamily='Outfit'
                  >
                    {cast.character}
                  </Typography>
                </Stack>
              </Container>
            </Stack>
          ))}
        </Slider>
      </Stack>
    </Paper>
  );
};

export default Cast;
