import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import {
  showWatchlistsWithPage,
  submitWatchlist,
  updateWatchlist,
  submitRating,
  showRatingByUser,
} from '../Utils/fetchData';
import { fetchMovieData } from '../Utils/fetchMovie';
import {
  Container,
  Paper,
  Stack,
  Typography,
  IconButton,
  Snackbar,
  Button,
  Divider,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  FormControl,
  InputLabel,
  Rating,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Paginate from '../Components/Pagination';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const key = process.env.REACT_APP_API_KEY;

const Watchlist = () => {
  class Watchlist {
    constructor(
      id,
      userid,
      movieid,
      watched,
      image,
      title,
      overview,
      value,
      tempvalue,
      inList,
      rated
    ) {
      this.id = id;
      this.userid = userid;
      this.movieid = movieid;
      this.watched = watched;
      this.image = image;
      this.title = title;
      this.overview = overview;
      this.value = value;
      this.tempvalue = tempvalue;
      this.inList = inList;
      this.rated = rated;
    }
  }

  const Navigate = useNavigate();
  const [watchlistData, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState('');
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [value, setValue] = useState(0);
  const page = searchParams.get('page') || 1;
  const [filter, setFilter] = useState(searchParams.get('filter') || 'all');
  // const [search, setSearch] = useState(searchParams.get('search') || '');
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get('search') || ''
  );

  const [timeOut, setTimeOut] = useState();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const handleFilter = async (e) => {
    setFilter(e.target.value);
    Navigate(`?page=${page}&filter=${e.target.value}`);
  };

  const handleWatchlist = async (movietitle, movieid) => {
    try {
      const res = await submitWatchlist({ title: movietitle }, movieid);
      const message = res.data.message;
      if (message === 'Watchlist Removed !')
        setAlertText('Removed from watchlist!');
      else setAlertText('Added to watchlist!');

      if (open === true) setOpen(false);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateWatchlist = async (movieid) => {
    try {
      const res = await updateWatchlist(movieid);

      if (res.data.watched === true) setAlertText('Movie Watched !');
      else setAlertText('Movie Unwatched !');

      if (open === true) setOpen(false);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRating = async (movieid, tempvalue) => {
    if (value === 0) return;
    try {
      await submitRating({ value: tempvalue }, movieid);
      setAlertText('Rating Saved!');

      if (open === true) setOpen(false);
      setOpen(true);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleSearch = (e, searchTerm) => {
    e.preventDefault();

    // setSearch(searchTerm);
    setList([]);

    clearTimeout(timeOut);
    setTimeOut(
      setTimeout(() => {
        fetchData(searchTerm);
      }, 500)
    );
    // setSearchTerm('');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const fetchMovie = async (movieid) => {
    try {
      const results = await fetchMovieData(`movie/${movieid}?api_key=${key}`);
      return `${results.poster_path}`;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOverview = async (movieid) => {
    try {
      const results = await fetchMovieData(`movie/${movieid}?api_key=${key}`);
      return `${results.overview}`;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRating = async (movieid) => {
    try {
      const {
        data: { ratings },
      } = await showRatingByUser(movieid);
      if (ratings) return `${ratings.value}`;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (searchTerm = '') => {
    try {
      const {
        data: { watchlists, pages },
      } = await showWatchlistsWithPage(page, filter, searchTerm);
      setTotalPages(pages);
      //setFav(favourites);

      watchlists.forEach(async (el) => {
        const image = await fetchMovie(el.movie_id);
        const overview = await fetchOverview(el.movie_id);
        const value = await fetchRating(el.movie_id);
        let rated = false;
        if (value) {
          rated = true;
        }
        setList((prev) => [
          ...prev,
          new Watchlist(
            el.id,
            el.user_id,
            el.movie_id,
            el.watched,
            image,
            el.movie_title,
            overview,
            value,
            value,
            true,
            rated
          ),
        ]);
      });

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = 'Watchlists - MovieDocs';
    const getUser = JSON.parse(localStorage.getItem('user'));
    setUser(getUser);
    setList([]);
    fetchData(searchTerm);
    window.scrollTo(0, 0);
    // }, [page, filter, search]);
  }, [page, filter]);

  // if (watchlistData.length < 1) return null;

  return (
    <div
      style={{
        minHeight: `calc(100vh - 220px)`,
      }}
    >
      <Container sx={{ width: '100%', height: '100%' }}>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            sx={{ width: '100%' }}
          >
            {alertText}
          </Alert>
        </Snackbar>
        {/* <Paginate page={page} total={totalPages} /> */}
        <Stack direction='row' pt={1}>
          <ThemeProvider theme={darkTheme}>
            <FormControl sx={{ width: '150px', ml: 2 }}>
              <InputLabel>Filter by</InputLabel>
              <Select
                sx={{ height: '45px' }}
                value={filter}
                label='Filter by'
                onChange={(e) => {
                  handleFilter(e);
                }}
              >
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='1'>Watched</MenuItem>
                <MenuItem value='0'>Not Watched</MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>

          <Paper
            component='form'
            onSubmit={handleSearch}
            sx={{
              border: '1px solid #e3e3e3',
              pl: 2,
              width: '250px',
              boxShadow: 'none',
              mr: { sm: 5 },
              ml: 2,
              // mt: 2,
              bgcolor: 'transparent',
            }}
          >
            <input
              style={{ height: '100%' }}
              type='text'
              className='searchBar'
              placeholder='Search watchlist here...'
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e, e.target.value);
              }}
            />
            {/* <IconButton type='submit'>
              <Search sx={{ color: 'white' }} />
            </IconButton> */}
          </Paper>
        </Stack>

        <Stack
          m={2}
          spacing={2}
          divider={<Divider orientation='horizontal' flexItem color='gray' />}
        >
          {watchlistData.map((el) => (
            <Stack key={el.id} direction='row'>
              <Link
                to={`/movie/${el.movieid}`}
                style={{
                  backgroundImage: el.image
                    ? `url(https://image.tmdb.org/t/p/original/${el.image})`
                    : 'url(https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png)',
                  width: '150px',
                  height: '200px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  transition: 'ease-in-out 0.6s',
                  borderRadius: 3,
                  boxShadow: 5,
                }}
              />
              <Container ml={2} sx={{ position: 'relative' }}>
                <Link to={`/movie/${el.movieid}`}>
                  <Typography
                    display='inline-block'
                    sx={{
                      color: 'white',
                      fontFamily: 'Poppins',
                      fontSize: '22px',
                      '&:hover': {
                        color: 'yellow',
                      },
                    }}
                  >
                    {el.title.charAt(0).toUpperCase() + el.title.slice(1)}
                  </Typography>
                </Link>
                <Typography mt={1} mb={1} color='lightgray'>
                  {el.overview}
                </Typography>

                <Stack
                  // direction='row'
                  direction={{ xs: 'column', md: 'row' }}
                  flexWrap={{ xs: 'wrap', md: 'none' }}
                  spacing={{ xs: 2 }}
                  sx={{
                    bottom: 1,
                    position: { xs: 'relative', md: 'absolute' },
                  }}
                >
                  <Button
                    variant='outlined'
                    startIcon={!el.inList ? <AddIcon /> : <RemoveIcon />}
                    onClick={() => {
                      if (!user) Navigate('/login');
                      //update db
                      handleWatchlist(el.title, el.movieid);

                      //change target favorite condition (fav or not)
                      const changeList = watchlistData.find(
                        (change) => change.id === el.id
                      );
                      changeList.inList = !changeList.inList;
                    }}
                    sx={[
                      el.inList && {
                        color: 'red',
                        border: 'red 1px solid',
                        opacity: 0.9,
                        '&:hover': {
                          border: 'red 1px solid',
                          opacity: 1,
                        },
                      },
                    ]}
                  >
                    Watchlist
                  </Button>

                  <Button
                    variant='outlined'
                    startIcon={
                      el.watched ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )
                    }
                    onClick={() => {
                      if (!user) return Navigate('/login');
                      //update db
                      handleUpdateWatchlist(el.movieid);

                      //change target favorite condition (fav or not)
                      const changeList = watchlistData.find(
                        (change) => change.id === el.id
                      );
                      changeList.watched = !changeList.watched;
                    }}
                    sx={[
                      { ml: 2 },
                      {
                        color: !el.watched ? '#24A0ED' : 'green',
                        border: !el.watched
                          ? '#24A0ED 1px solid'
                          : 'green 1px solid',
                        opacity: 0.9,
                        '&:hover': {
                          border: !el.watched
                            ? '#24A0ED 1px solid'
                            : 'green 1px solid',
                          opacity: 1,
                        },
                      },
                    ]}
                  >
                    Watched
                  </Button>

                  {el.watched ? (
                    <Rating
                      value={el.tempvalue / 2}
                      onChangeActive={(e) => {}}
                      onChange={(e) => {
                        setValue(parseFloat(e.target.value) * 2);
                        const changeList = watchlistData.find(
                          (change) => change.id === el.id
                        );
                        changeList.tempvalue = parseFloat(e.target.value) * 2;
                        changeList.rated = false;
                      }}
                      precision={0.5}
                      size='large'
                      sx={{ ml: 2 }}
                      emptyIcon={
                        <StarBorderIcon
                          fontSize='inherit'
                          sx={{ opacity: 0.7, color: 'white', zIndex: -1 }}
                        />
                      }
                    />
                  ) : (
                    <></>
                  )}

                  {el.watched && !el.rated ? (
                    <IconButton
                      onClick={() => {
                        handleRating(el.movieid, el.tempvalue);

                        const changeList = watchlistData.find(
                          (change) => change.id === el.id
                        );
                        changeList.value = el.tempvalue;
                        changeList.rated = true;
                      }}
                      title='Submit'
                      component='label'
                      sx={{
                        color: 'blue',
                        backgroundColor: 'white',
                        ml: 2,
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                      }}
                    >
                      <CheckIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}

                  {el.watched && !el.rated ? (
                    <IconButton
                      onClick={() => {
                        setValue(el.value);

                        const changeList = watchlistData.find(
                          (change) => change.id === el.id
                        );
                        changeList.tempvalue = el.value;
                        changeList.rated = true;
                      }}
                      title='Cancel'
                      component='label'
                      sx={{
                        color: 'blue',
                        backgroundColor: 'white',
                        ml: 2,
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                      }}
                    >
                      <ClearIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Container>
            </Stack>
          ))}
        </Stack>

        {/* First version V V V */}
        {/* <Stack
          direction='row'
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 3,
          }}
        >
          {watchlistData.map((el) => (
            <div key={el.id} style={{ position: 'relative' }}>
              <Container
                key={el.id}
                elevation={5}
                sx={{
                  m: 1,
                  position: 'relative',
                  display: 'flex',
                  width: { xs: '100px', md: '200px' },
                  height: { xs: '200px', md: '300px' },
                  backgroundImage: el.image
                    ? `url(https://image.tmdb.org/t/p/original${el.image})`
                    : 'url(https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  transition: 'ease-in-out 0.4s',
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.0)',
                  },
                }}
                onClick={() => {
                  Navigate(`/movie/${el.movieid}`);
                }}
              >
              </Container>
              <IconButton
                onClick={() => {
                  if (!user) return Navigate('/login');
                  //update db
                  handleWatchlist(el.movieid);

                  //change target favorite condition (fav or not)
                  const changeList = watchlistData.find(
                    (change) => change.id === el.id
                  );
                  changeList.inList = !changeList.inList;
                }}
                component='label'
                title={el.inList ? "Remove from watchlist" : "Add to watchlist"}
                sx={{
                  color: 'gray',
                  backgroundColor: 'white',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  margin: 2,
                  opacity: 1,
                  '&:hover': {
                    opacity: 1,
                    backgroundColor: 'white',
                    transform: 'scale(1.2)',
                  },
                }}
              >
                {el.inList ? <PlaylistRemoveIcon sx={{fontSize: '20px'}}/> : <AddToQueueIcon sx={{fontSize: '20px'}} />}
              </IconButton>

              <IconButton
                onClick={() => {
                  if (!user) return Navigate('/login');
                  //update db
                  handleUpdateWatchlist(el.movieid);

                  //change target favorite condition (fav or not)
                  const changeList = watchlistData.find(
                    (change) => change.id === el.id
                  );
                  changeList.watched = !changeList.watched;
                }}
                component='label'
                title={el.watched ? "Set to not watched" : "Set to watched"}
                sx={{
                  color: 'gray',
                  backgroundColor: 'white',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  margin: 2,
                  opacity: 1,
                  '&:hover': {
                    opacity: 1,
                    backgroundColor: 'white',
                    transform: 'scale(1.2)',
                  },
                }}
              >
                {el.watched ? <VisibilityOffIcon sx={{fontSize: '20px'}}/> : <VisibilityIcon sx={{fontSize: '20px'}} />}
              </IconButton>

              <Button
                   variant='outlined'
                   startIcon={!el.inList ? <AddIcon /> : <RemoveIcon />}
                   onClick={() => {
                     if (!user) Navigate('/login');
                     else {
                       //update db
                       handleWatchlist(el.movieid);

                       //change target favorite condition (fav or not)
                       const changeList = watchlistData.find(
                         (change) => change.id === el.id
                       );
                       changeList.inList = !changeList.inList;
                     }
                   }}
                   sx={[
                     { ml: 2 },
                     el.inList && {
                       color: 'red',
                       border: 'red 1px solid',
                       opacity: 0.9,
                       '&:hover': {
                         border: 'red 1px solid',
                         opacity: 1,
                       },
                     },
                   ]}
                 >
                   Watchlist
                 </Button>
            </div>
          ))}
        </Stack> */}
        <Paginate page={page} total={totalPages} query={searchTerm} />
      </Container>
    </div>
  );
};

export default Watchlist;
