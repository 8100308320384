import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';

const NoPage = () => {
  return (
    <div
      style={{
        minHeight: `calc(100vh - 220px)`,
      }}
    >
      <Typography variant='h3' fontFamily='Open Sans' align='center' pt={10}>
        No Page found
      </Typography>
    </div>
  );
};

export default NoPage;
