import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { fetchMovieData } from '../Utils/fetchMovie';

const API_key = process.env.REACT_APP_API_KEY;

const VideoLayer = ({ id, close }) => {
  const [trailer, setTrailer] = useState('');

  const closePlayer = () => {
    close();
  };

  useEffect(() => {
    const fetchTrailer = async () => {
      try {
        const { results } = await fetchMovieData(
          `movie/${id}/videos?api_key=${API_key}&language=en-US`
        );

        const latestTrailer = results.filter(
          (result) => result.type === 'Trailer'
        )[0];

        if (!latestTrailer) setTrailer(results[0].key);
        else setTrailer(latestTrailer.key);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTrailer();
  });

  if (!trailer) return null;

  return (
    <>
      <div
        style={{
          backgroundColor: 'black',
          // width: '100%',
          // height: '100%',
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          opacity: 0.75,
          zIndex: 6,
        }}
        onClick={closePlayer}
      ></div>
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          width='80%'
          height='90%'
          style={{
            zIndex: 6,
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          url={`https://www.youtube.com/watch?v=${trailer}`}
          controls
          playing
        />
      </div>
    </>
  );
};

export default VideoLayer;
