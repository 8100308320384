import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { showUser, updateUser, fetchAvatar } from '../Utils/fetchData';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Stack,
  Snackbar,
  TextField,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import MuiAlert from '@mui/material/Alert';
import bcrypt from 'bcryptjs';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
const api_url = process.env.REACT_APP_MOVIEDOCS_API;

const styles = makeStyles({
  multilineColor:{
      color:'black'
  }
})

const Profile = () => {
  class Avatars {
    constructor(image_path, isChecked) {
      this.image_path = image_path;
      this.isChecked = isChecked;
    }
  }

  const [avatarData, setAvatar] = useState([]);
  const [choosenImg, setChoosenImage] = useState('');
  // const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState({});
  const [isShown, setIsShown] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [username, setUsername] = useState("");
  const [pass, setPassword] = useState("");
  const [oldPass, setOldPassword] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState('');
  const [err, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("")
  const Navigate = useNavigate();

  const classes = styles();

  const toggleSwitch = event => {
    setIsShown(current => !current);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
    setOpen(false);
  };

  const validateEmail = (email) => {
    if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    alert('You have entered an invalid email address!');
    return false;
  };

  const handleSave = async () => {
    if (
      username === '' ||
      firstName === '' ||
      lastName === '' ||
      email === ''
    ) {
      setMessage("Textbox should not empty")
      setError(true)
      setOpen(true)
      return;
    }
    const validEmail = validateEmail(email);

    if (!validEmail) {
      return;
    }

    const checkPassword = await bcrypt.compare(oldPass, user.password);

    if (pass !== '' && !checkPassword){
      setMessage("Wrong Password")
      setError(true)
      setOpen(true)
      return
    };

    try {
      const res = await updateUser({
        username, password: pass, firstName, lastName, email, image_path: image
      });

      if (res.status === 201){
        setMessage("User Updated!")
        setOpen(true)
        setAvatar([])
        fetchData()
      }
      else{
        setMessage(res.data.error)
        setError(true)
        setOpen(true)
      }

    } catch (error) {
      setMessage(error.response.data.error)
      setError(true)
      setOpen(true)
    }
  };

  const handleEditAvatar = async () => {
    if (choosenImg === ''){
        setMessage("Choose an image")
        setError(true)
        setOpen(true)
        return
    }

    try {
        setImage(choosenImg)
    } catch (error) {
        setMessage(error.response.data.error)
        setError(true)
        setOpen(true)
    }
  };

  const resetInput = async () => {
    setfirstName(user.firstName)
    setlastName(user.lastName)
    setUsername(user.username)
    setEmail(user.email)
    setPassword("")
    setOldPassword("")
    setImage(user.image_path)
    setAvatar([])
    fetchAvatarData()
  }

  const fetchData = async () => {
    try {
      const res = await showUser();
      setUser(res.data.user)
      setfirstName(res.data.user.firstName)
      setlastName(res.data.user.lastName)
      setUsername(res.data.user.username)
      setEmail(res.data.user.email)
      setImage(res.data.user.image_path)
      setChoosenImage(res.data.user.image_path)

      //fetch avatars
      const result = await fetchAvatar();
      result.data.forEach(async (ava) => {
        let checked = false
        if (res.data.user.image_path === ava){
            checked = true
        }
        setAvatar((prev) => [
          ...prev,
          new Avatars(ava, checked),
        ]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAvatarData = async () => {
    try {
      const res = await fetchAvatar();

      res.data.forEach(async (ava) => {
        let checked = false
        if (user.image_path === ava){
            checked = true
        }
        setAvatar((prev) => [
          ...prev,
          new Avatars(ava, checked),
        ]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = 'Profile - MovieDocs';
    setAvatar([]);
    fetchData();
  }, []);
  return (
    <Grid container>
      <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity={err ? 'error' : 'success'}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
      </Snackbar>

      {/* start */}
      <Grid xs={3} md={3}>
      </Grid>

      <Grid container xs={6} md={6} sx={{
        backgroundColor: '#EFEFEF',
        borderRadius: '10px',
        
      }}>
        {/* picture */}
        <Grid xs={6} md={6} xsOffset={3} mdOffset={3} direction="row" className='userValue' sx={{
          textAlign: 'center',
          justifyContent: "center",
          display: isShown ? "flex" : "none",
          mt: '20px',
          position: 'relative'
        }}>
          {/* <Avatar sx={{
            width: '120px',
            height: '120px'
          }} /> */}
          <img src={(user.image_path)? api_url+"/images/"+(user.image_path) : api_url+"/images/default.png" } alt="avatar" width="120px" height="120px" className="roundedcorner" />
        </Grid>
        {/* end picture */}

        {/* picture edit */}
        <Grid xs={6} md={6} xsOffset={3} mdOffset={3} direction="row" className='userValue' sx={{
          textAlign: 'center',
          justifyContent: "center",
          display: isShown ? "none" : "flex",
          mt: '20px',
          position: 'relative'
        }}>
          {/* <Avatar sx={{
            width: '120px',
            height: '120px'
          }} /> */}
          <img src={image? api_url+"/images/"+image : api_url+"/images/default.png" } alt="avatar" width="120px" height="120px" className="roundedcorner" />
          {/* <IconButton
            onClick={() => {
              // if (image){
              //   Navigate(`/edit_avatar/?imgpath=${image}`)
              // }
              // else{
              //   Navigate(`/edit_avatar`)
              // }
              
            }}
            component='label'
            sx={{
              color: 'gray',
              backgroundColor: 'lightgray',
              position: 'absolute',
              top: 0,
              right: 70,
              margin: 2,
              opacity: 1,
              '&:hover': {
                opacity: 1,
                backgroundColor: 'darkgray',
                transform: 'scale(1.1)',
              },
            }}
          >
            <EditIcon sx={{ fontSize: '20px' }} />
          </IconButton> */}
          <Popup trigger={
            <IconButton
              component='label'
              sx={{
                color: 'gray',
                backgroundColor: 'lightgray',
                position: 'absolute',
                top: 0,
                right: 70,
                margin: 2,
                opacity: 1,
                '&:hover': {
                  opacity: 1,
                  backgroundColor: 'darkgray',
                  transform: 'scale(1.1)',
                },
              }}
            >
              <EditIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          } modal nested>
            {close => (
              <div style={{color: 'black'}}>
                <IconButton
                  onClick={close}
                  component='label'
                  sx={{
                    color: 'black',
                    backgroundColor: 'lightgray',
                    border: '1px solid black',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-20px',
                    right: '-20px',
                    opacity: 1,
                    '&:hover': {
                      opacity: 1,
                      backgroundColor: 'gray',
                      transform: 'scale(1.0)',
                    },
                  }}
                >
                  <CloseIcon scx={{fontSize: '20px'}} />
                </IconButton>
                <Container sx={{ width: '100%', height: '100%' }}>
                  <Typography fontSize={20} margin={2} fontFamily='Open Sans'>
                    Choose an Image
                  </Typography>
                  <Stack
                    direction='row'
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: 3,
                    }}
                  >
                    {avatarData.map((ava) => (
                      <div key={ava.image_path} style={{ position: 'relative' }}>
                        <Container
                          key={ava.image_path}
                          elevation={5}
                          sx={{
                            m: 1,
                            position: 'relative',
                            display: 'flex',
                            width: { xs: '50px', md: '100px' },
                            height: { xs: '50px', md: '100px' },
                            backgroundImage: ava.image_path
                              ? `url(${api_url}/images/${ava.image_path})`
                              : `url(${api_url}/images/default.png)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            transition: 'ease-in-out 0.4s',
                            borderRadius: '50%',
                            border: '2px solid gray',
                            cursor: 'pointer',
                            '&:hover': {
                              transform: 'scale(1.1)',
                            },
                          }}
                          onClick={() => {
                            if (!user) return Navigate('/login');
                            const resetCheck = avatarData.find(
                              (change) => change.isChecked === true
                            );
                            if (resetCheck){
                                resetCheck.isChecked = false
                            }

                            const changeChecked = avatarData.find(
                              (change) => change.image_path === ava.image_path
                            );
                            changeChecked.isChecked = !changeChecked.isChecked;
                            setChoosenImage(ava.image_path);
                          }}
                        ></Container>
                        <IconButton
                          onClick={() => {
                            if (!user) return Navigate('/login');
                            const resetCheck = avatarData.find(
                              (change) => change.isChecked === true
                            );
                            if (resetCheck){
                                resetCheck.isChecked = false
                            }
                            
                            const changeChecked = avatarData.find(
                              (change) => change.image_path === ava.image_path
                            );
                            changeChecked.isChecked = !changeChecked.isChecked;
                            setChoosenImage(ava.image_path);
                          }}
                          component='label'
                          sx={{
                            color: ava.isChecked ? 'blue' : 'gray',
                            backgroundColor: 'white',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            opacity: 1,
                            '&:hover': {
                              opacity: 1,
                              backgroundColor: 'white',
                              transform: 'scale(1.0)',
                            },
                          }}
                        >
                          {ava.isChecked? <CheckBoxIcon sx={{ fontSize: '20px' }} /> : <CheckBoxOutlineBlankIcon sx={{ fontSize: '20px' }} />}
                        </IconButton>
                      </div>
                    ))}
                  </Stack>
                  <Grid xs={6} xsOffset={3} md={6} mdOffset={3} sx={{textAlign: 'center'}}>
                    <Button
                        variant='contained'
                        onClick={() => {
                          handleEditAvatar();
                          close();
                        }}
                        sx={{ mt: 1, mb: 3, mr: 3 }}
                    >
                        Save
                    </Button>

                    <Button
                        variant='contained'
                        onClick={() => {
                          close();
                        }}
                        sx={{ mt: 1, mb: 3 }}
                    >
                        Back
                    </Button>
                  </Grid>
                </Container>
              </div>
            )}
          </Popup>
        </Grid>
        {/* end picture edit */}

        {/* toggle button */}
        <Grid xs={3} md={3} sx={{
          textAlign: 'right'
        }}>
          <IconButton
            aria-label='toggle password visibility'
            onClick={toggleSwitch}
            onMouseDown={handleMouseDownPassword}
          >
            {isShown ? <EditIcon /> : <EditOffIcon />}
          </IconButton>
        </Grid>
        {/* end toggle button */}

        {/* name */}
        <Grid xs={6} md={6} xsOffset={3} mdOffset={3} className='userValue' sx={{
          textAlign: 'center',
          display: isShown ? 'block' : 'none'
        }}>
          <Typography sx={{
                color: 'black',
                fontFamily: 'Outfit',
                fontSize: '26px',
                fontWeight: 'bold',
              }}>
            {user.firstName} {user.lastName}
          </Typography>
          
        </Grid>
        {/* end name */}

        {/* firstname edit when toggle on */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} className='userValue' sx={{
          display: isShown ? 'none' : 'block'
        }}>
          <TextField
            label='First Name'
            id='firstname'
            inputProps={{className: classes.multilineColor}}
            value={firstName}
            onChange={(e) => {setfirstName(e.target.value)}}
          />
          
        </Grid>
        {/* end firstname edit when toggle on */}

        {/* lastname edit when toggle on */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} className='userValue' sx={{
          display: isShown ? 'none' : 'block'
        }}>
          <TextField
            label='Last Name'
            id='lastname'
            inputProps={{className: classes.multilineColor}}
            value={lastName}
            onChange={(e) => {setlastName(e.target.value)}}
          />
        </Grid>
        {/* end lastname edit when toggle on */}
        
        {/* label username show */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} sx={{
          fontFamily: 'Outfit',
          color: 'black',
          display: isShown ? 'block' : 'none'
        }}>
          Username
        </Grid>
        {/* end label username show */}

        {/* username show */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} className='userValue' sx={{
          display: isShown ? 'block' : 'none'
        }}>
          <Typography sx={{
              color: 'black',
              fontFamily: 'Outfit',
              fontSize: '26px',
              fontWeight: 'bold',
            }}>
              {user.username}
          </Typography>
        </Grid>
        {/* end username show */}
        
        {/* username edit when toggle on */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} className='userValue' sx={{
          display: isShown ? 'none' : 'block'
        }}>
          <TextField
            label='Username'
            id='username'
            value={username}
            onChange={(e) => {setUsername(e.target.value)}}
          />
        </Grid>
        {/* end username edit when toggle on */}

        {/* label email show */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} sx={{
          fontFamily: 'Outfit',
          color: 'black',
          display: isShown ? 'block' : 'none'
        }}>
          email
        </Grid>
        {/* end label email show */}

        {/* email show */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} className='userValue' sx={{
          display: isShown ? 'block' : 'none'
        }}>
          <Typography sx={{
              color: 'black',
              fontFamily: 'Outfit',
              fontSize: '26px',
              fontWeight: 'bold',
            }}>
              {user.email}
          </Typography>
        </Grid>
        {/* end email show */}

        {/* email edit when toggle on */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} className='userValue' sx={{
          display: isShown ? 'none' : 'block'
        }}>
          <TextField
            label='Email'
            id='email'
            value={email}
            onChange={(e) => {setEmail(e.target.value)}}
          />
        </Grid>
        {/* end email edit when toggle on */}

        {/* password */}
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} className='userValue' sx={{
          display: isShown ? 'none' : 'block',
        }}>
          <FormControl
            variant='outlined'
          >
            <InputLabel htmlFor='outlined-adornment-password'>
              New Password
            </InputLabel>
            <OutlinedInput
                id='newpassword'
                type={showPassword ? 'text' : 'password'}
                value={pass}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                focused='true'
    
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='New Password'
              />
          </FormControl>
        </Grid>
        
        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} className='userValue' sx={{
          display: isShown ? 'none' : 'block'
        }}>
          <FormControl
            variant='outlined'
          >
            <InputLabel htmlFor='outlined-adornment-password'>
              Old Password
            </InputLabel>
            <OutlinedInput
                id='password'
                type={showOldPassword ? 'text' : 'password'}
                value={oldPass}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                focused='true'
    
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Old Password'
              />
          </FormControl>
        </Grid>
        {/* end password */}

        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} sx={{
          display: isShown ? 'none' : 'block'
        }}>
          <Button
            variant='contained'
            onClick={handleSave}
            sx={{ mt: 1, mb: 3, mr: 3 }}
          >
            Save Changes
          </Button>

          <Button
            variant='contained'
            onClick={() => {
              resetInput()
            }}
            sx={{ mt: 1, mb: 3 }}
          >
            Reset
          </Button>
        </Grid>

        <Grid xs={6} xsOffset={3} md={6} mdOffset={3} sx={{
          display: isShown ? 'block' : 'none'
        }}>
          <Button
            variant='contained'
            onClick={() => {
              Navigate('/user_delete')
            }}
            sx={{ mt: 1, mb: 3, mr: 3 }}
          >
            Delete Account
          </Button>
        </Grid>
      </Grid>
      <Grid xs={3} md={3}>
      </Grid>
      {/* end */}
    </Grid>
  );
};

export default Profile;