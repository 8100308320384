import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Stack,
  Avatar,
  styled,
  Divider,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  FormControl,
  InputLabel,
  Skeleton,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import { fetchMovieData } from '../Utils/fetchMovie';
import { fetchReviewVoted, showAll, submitUpvote } from '../Utils/fetchData';
import { Paginate } from '../Components';

const key = process.env.REACT_APP_API_KEY;

const Review = () => {
  class Review {
    constructor(id, username, score, message, imagePath) {
      this.id = id;
      this.username = username;
      this.score = score;
      this.message = message;
      this.imagePath = imagePath;
    }
  }

  class Vote {
    constructor(id, totalVote, userVote) {
      this.id = id;
      this.totalVote = totalVote;
      this.userVote = userVote;
    }
  }

  const Navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState(searchParams.get('sort') || 'latest');
  const [movie, setMovie] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [votes, setVotes] = useState([]);
  const [pages, setPages] = useState(0);
  const page = searchParams.get('page') || 1;
  const user = JSON.parse(localStorage.getItem('user'));

  let { id } = useParams();

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
      : Math.sign(num) * Math.abs(num);
  }

  const ReviewName = styled(Typography)({
    color: 'white',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  });

  const ReviewText = styled(Typography)({
    color: 'white',
    fontFamily: 'Open Sans',
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const fetchMovie = async () => {
    try {
      const results = await fetchMovieData(`movie/${id}?api_key=${key}`);
      setMovie(results);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReviews = async () => {
    setReviews([]);
    try {
      const {
        data: { reviews, pages },
      } = await showAll(id, page, sort);
      setPages(pages);
      reviews.forEach(async (review) => {
        setReviews((prev) => [
          ...prev,
          new Review(
            review.id,
            review.User.username,
            review.User.Ratings[0].value,
            review.message,
            review.User.image_path
          ),
        ]);

        let userVoted;

        if (user) {
          const {
            data: { votes },
          } = await fetchReviewVoted(review.id);

          if (votes) userVoted = true;
          else userVoted = false;
        } else {
          userVoted = false;
        }

        setVotes((prev) => [
          ...prev,
          new Vote(review.id, review.upvote, userVoted),
        ]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSort = async (e) => {
    setSort(e.target.value);
    Navigate(`?page=${page}&sort=${e.target.value}`);
  };

  const submitVote = async (id) => {
    if (!user) Navigate('/login');
    else
      try {
        const {
          data: { checkreview },
        } = await submitUpvote(id);

        const updatedVote = votes.find((vote) => vote.id === id);
        updatedVote.userVote = !updatedVote.userVote;
        updatedVote.totalVote = checkreview.upvote;

        const newVote = votes.filter((vote) => vote.id !== id);
        setVotes(newVote);
        setVotes((prev) => [...prev, updatedVote]);
      } catch (error) {
        console.log(error);
      }
  };

  useEffect(() => {
    fetchMovie();
    fetchReviews();
  }, [sort, page]);

  if (movie === null) return null;

  document.title = movie?.title + ' review - MovieDocs';

  return (
    <div
      style={{
        minHeight: `calc(100vh - 220px)`,
      }}
    >
      <Container>
        <Stack direction='row' justifyContent='space-between' padding={1}>
          <Typography fontSize={30} fontFamily='Poppins'>
            {movie.title} Reviews
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <FormControl sx={{ width: '150px' }}>
              <InputLabel>Sort by</InputLabel>
              <Select
                value={sort}
                label='Sort by'
                onChange={(e) => {
                  handleSort(e);
                }}
              >
                <MenuItem value='popularity'>Popularity</MenuItem>
                <MenuItem value='latest'>Latest</MenuItem>
                <MenuItem value='oldest'>Oldest</MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>
        </Stack>
        <Stack mb={2}>
          {!loading ? (
            reviews.length > 0 ? (
              reviews.map((review, index) => (
                <Grid
                  container
                  key={index}
                  sx={{
                    backgroundColor: '#2C3E50',
                    borderRadius: 2,
                    mt: 2,
                    padding: 2,
                  }}
                >
                  <Grid xs={2} md={1} justifyContent='center' display='flex'>
                    <Stack alignItems='center'>
                      <Avatar
                        sx={{ width: 32, height: 32 }}
                        src={`http://localhost:8080/images/${review.imagePath}`}
                      >
                        {review.username.split('')[0]}
                      </Avatar>
                      <IconButton
                        sx={{ mt: 2 }}
                        onClick={() => {
                          submitVote(review.id);
                        }}
                      >
                        {votes.find((vote) => vote.id === review.id)
                          ?.userVote ? (
                          <ThumbUpAltIcon sx={{ color: '#3A7BBB' }} />
                        ) : (
                          <ThumbUpOffAltIcon sx={{ color: '#3A7BBB' }} />
                        )}
                      </IconButton>
                      <Typography>
                        {votes.find((vote) => vote.id === review.id)
                          ?.totalVote &&
                          kFormatter(
                            votes.find((vote) => vote.id === review.id)
                              ?.totalVote
                          )}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid xs={10} md={11}>
                    <Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        mb={1}
                        divider={
                          <Divider
                            orientation='vertical'
                            flexItem
                            color='gray'
                            light
                            variant='medium'
                          />
                        }
                      >
                        <ReviewName>{review.username}</ReviewName>
                        <Typography
                          fontSize='16px'
                          sx={{
                            ml: 2,
                            color:
                              review.score < 4
                                ? 'red'
                                : review.score > 7
                                ? 'green'
                                : 'yellow',
                            fontFamily: 'Open Sans',
                            fontWeight: 'bold',
                          }}
                        >
                          {review.score}
                        </Typography>
                      </Stack>
                      <ReviewText>{review.message}</ReviewText>
                    </Stack>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography variant='subtitle1'>
                There are no reviews here yet...
              </Typography>
            )
          ) : (
            <>
              <Skeleton height={110} sx={{ bgcolor: 'grey.700' }} />
              <Skeleton height={60} sx={{ bgcolor: 'grey.700' }} />
              <Skeleton height={70} sx={{ bgcolor: 'grey.700' }} />
            </>
          )}
        </Stack>
        <Paginate sort={sort} page={page} total={pages} />
      </Container>
    </div>
  );
};

export default Review;
