import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import MovieIcon from '@mui/icons-material/Movie';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { logout } from '../Utils/fetchUser';
const api_url = process.env.REACT_APP_MOVIEDOCS_API;

export default function AvatarMenu() {
  const Navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [username, setUsername] = React.useState('');
  const user = JSON.parse(localStorage.getItem('user'));

  React.useEffect(() => {
    // const user = JSON.parse(localStorage.getItem('user'));
    if (user) setUsername(user.user.username);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    Navigate('/profile');
  };

  const handleLogout = async () => {
    localStorage.removeItem('user');
    try {
      const res = await logout();
      console.log(res);
    } catch (error) {
      console.log(error);
    }
    Navigate('/');
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          position: { xs: 'absolute', md: 'relative' },
          right: { xs: '20px' },
          top: { xs: '11px', md: 0 },
        }}
      >
        <Tooltip title='Account settings'>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={`${api_url}/images/${user.user.image_path}`}>
              {username.split('')[0]}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfile}>
          <Avatar src={`${api_url}/images/${user.user.image_path}`}/> {username}
        </MenuItem>
        <MenuItem
          onClick={() => {
            Navigate('/reviews');
          }}
        >
          <ListItemIcon>
            <ReviewsIcon fontSize='small' />
          </ListItemIcon>
          Reviews
        </MenuItem>
        <MenuItem
          onClick={() => {
            Navigate('/watchlist');
          }}
        >
          <ListItemIcon>
            <MovieIcon fontSize='small' />
          </ListItemIcon>
          Watchlist
        </MenuItem>
        <MenuItem
          onClick={() => {
            Navigate('/favourite');
          }}
        >
          <ListItemIcon>
            <FavoriteIcon fontSize='small' />
          </ListItemIcon>
          Favorite
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
