import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Container,
  Button,
  Stack,
  IconButton,
  Snackbar,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GradeIcon from '@mui/icons-material/Grade';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import MuiAlert from '@mui/material/Alert';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { fetchMovieData } from '../Utils/fetchMovie';
import { submitWatchlist } from '../Utils/fetchData';
import './styles.css';

const key = process.env.REACT_APP_API_KEY;

const MovieSlide = ({ title, onClick, watchlists, user, id, throwId }) => {
  const [valid, setValid] = useState(true);
  const [movies, setMovies] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const Navigate = useNavigate();
  const [existingIds, setExistingIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  let url;
  let dest;

  if (title === 'In Theather') {
    url = `movie/now_playing?api_key=${key}&language=en-US&page=1`;
    dest = '/playing';
  } else if (title === 'Coming Soon') {
    url = `movie/upcoming?api_key=${key}&language=en-US&page=1`;
    dest = '/upcoming';
  } else if (title === 'Recommendations') {
    url = `movie/${id}/similar?api_key=${key}&language=en-US&page=1`;
    dest = '';
  }

  useEffect(() => {
    // if (watchlists.length > 0) {
    if (user) {
      setExistingIds(watchlists.map((watchlists) => watchlists.movie_id));
      setValid(false);
    }

    const fetchData = async () => {
      try {
        const { results } = await fetchMovieData(url);
        setMovies(results);
        // if (watchlists.length > 0) {
        const data = results.map((result) => {
          return { id: result.id, inList: false };
        });
        setPlaylists(data);
        // }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (playlists.length > 0 && valid === false) {
      playlists.forEach((playlist) => {
        if (existingIds.find((id) => id === playlist.id))
          playlist.inList = true;
      });
      setValid(true);
    }
  }, [playlists]);

  const handleClick = (id) => {
    onClick(id);
  };

  const handleWatchlist = async (id, title) => {
    try {
      const res = await submitWatchlist({ title: title }, id);
      const message = res.data.message;
      if (message === 'Watchlist Removed !')
        setAlertText('Removed from watchlist!');
      else setAlertText('Added to watchlist!');
      if (open === true) setOpen(false);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const toDest = () => {
    Navigate(dest);
  };

  const settings = {
    draggable: false,
    // swipe: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    onSwipe: () => {},
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  if (movies === null || valid === false) return null;

  return (
    <>
      <Container maxWidth='false' sx={{ ml: { xs: 1, md: 3, lg: 4 } }}>
        <Typography
          sx={{
            display: 'inline-block',
            mt: 5,
            mb: 2,
            fontSize: '32px',
            fontFamily: 'Poppins',
            cursor: 'pointer',
            pointerEvents: title === 'Recommendations' && 'none',
            '&:hover': { color: 'yellow' },
          }}
          onClick={toDest}
        >
          {title}
        </Typography>

        <div style={{ width: '95%', left: '0px', position: 'relative' }}>
          <Slider {...settings}>
            {movies.map((movie, index) => (
              <div key={movie.id} style={{ position: 'relative' }}>
                <Container
                  onClick={() => {
                    if (throwId) throwId(movie.id);
                    Navigate(`/movie/${movie.id}`);
                  }}
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    width: { xs: '100%', md: '200px' },
                    height: { xs: '400px', md: '300px' },
                    backgroundImage: movie.poster_path
                      ? `url(https://image.tmdb.org/t/p/original/${movie.poster_path})`
                      : 'url(https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    transition: 'ease-in-out 0.6s',
                    borderRadius: 1,
                    cursor: 'pointer',
                  }}
                ></Container>

                <IconButton
                  onClick={() => {
                    if (!user) return Navigate('/login');
                    handleWatchlist(movie.id, movie.title);
                    const changeList = playlists.find(
                      (playlist) => playlist.id === movie.id
                    );
                    changeList.inList = !changeList.inList;
                    const newPlaylists = playlists.filter(
                      (playlist) => playlist.id !== movie.id
                    );
                    setPlaylists(newPlaylists);
                    setPlaylists((playlists) => [...playlists, changeList]);
                  }}
                  component='label'
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    top: 0,
                    padding: 1,
                    opacity: 0.5,
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                >
                  {playlists.find(
                    (playlist) =>
                      playlist.id === movie.id && playlist.inList === true
                  ) ? (
                    <PlaylistRemoveIcon />
                  ) : (
                    <AddToQueueIcon />
                  )}
                </IconButton>

                <Stack
                  direction='row'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant='outlined'
                    startIcon={<PlayArrowIcon />}
                    onClick={(e) => handleClick(movie.id)}
                    sx={{
                      color: 'white',
                      border: 'none',
                      fontSize: { xs: '12px', md: '16px' },
                      '&:hover': {
                        backgroundColor: '#212F3D',
                        color: '#3498DB',
                        border: 'none',
                        boxShadow: 2,
                      },
                    }}
                  >
                    trailer
                  </Button>
                  <div style={{ display: 'flex', marginRight: '5px' }}>
                    <GradeIcon sx={{ ml: 1, color: 'yellow' }} />
                    <Typography sx={{ ml: 1, opacity: 0.8 }}>
                      {Math.round(movie?.vote_average * 10) / 10}
                    </Typography>
                  </div>
                </Stack>
              </div>
            ))}
          </Slider>
        </div>
      </Container>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          {alertText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MovieSlide;
