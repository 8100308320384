import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { ChangePassBox } from '../Components';

const ChangePass = () => {
  document.title = 'Forgot Pass - MovieDocs';
  return (
    <Grid container>
      <Grid xs={12}>
        <ChangePassBox />
      </Grid>
    </Grid>
  );
};

export default ChangePass;