import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Stack } from '@mui/system';
import { Button, FormHelperText, Typography } from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { login } from '../Utils/fetchUser';
import { changePass } from '../Utils/fetchData';

export default function ChangePassBox() {
  const Navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const [falseInfo, setFalseInfo] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const authToken = searchParams.get('token') || '';

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePass = async (e) => {
    if (
        values.password === ''
      ) {
        setError(true);
        setFalseInfo(false);
        setSuccess(false);
        return;
      }
    
    try {
      const res = await changePass({
        newPass: values.password, authToken
      });

      if (res.status === 200){
        setError(false);
        setFalseInfo(false);
        setSuccess(true);
      }
      else if (res.status === 400) {
        setError(false);
        setFalseInfo(true);
        setSuccess(false);
        setMessage(res.data.error)
      }
      else{
        setError(false);
        setFalseInfo(true);
        setSuccess(false);
        setMessage('Error occured. Try again')
      }
    } catch (error) {
      setError(false);
      setFalseInfo(true);
      setSuccess(false);
      setMessage(error.response.data.error)
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Stack
        direction='column'
        sx={{
          height: { xs: '100vh', md: '60vh' },
          width: { xs: '100vw', md: '25vw' },
          backgroundColor: '#EFEFEF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          border: '1px solid black',
          boxShadow: 5,
        }}
      >
        <Typography
          color='black'
          sx={{
            backgroundColor: '#369BFB',
            width: '100%',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            fontSize: '24px',
            fontFamily: 'Poppins',
            mb: 3,
            top: 0,
            position: 'absolute',
          }}
        >
          Change Password
        </Typography>
        <Typography align='justify' sx={{
            width: '30ch',
            color: 'black',
            fontFamily: 'Outfit',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '20px'
        }}>
            Enter your new password.
        </Typography>
        
        <form onSubmit={(e) => handleChangePass()}>
          <FormControl sx={{ width: '30ch' }} variant='outlined'>
            <InputLabel htmlFor='outlined-adornment-password'>
              Password
            </InputLabel>

            <OutlinedInput
              id='password'
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Password'
            />
            <FormHelperText>
              {error
                ? 'Password cannot be empty'
                : falseInfo
                ? message
                : success
                ? 'Password successfully changed!'
                : ''}
            </FormHelperText>
          </FormControl>
        </form>

        <Button
          variant='contained'
          onClick={handleChangePass}
          sx={{ mt: 1, mb: 1 }}
        >
          Submit Password
        </Button>
        <Typography
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            bottom: { xs: '10px', md: '20px' },
          }}
        >
          <Link to='/login' style={{ color: '#2587BB', fontFamily: 'Poppins' }}>
            Sign in
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
