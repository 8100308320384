import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Stack,
  styled,
  Divider,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  FormControl,
  InputLabel,
  IconButton,
  Backdrop,
  Paper,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  deleteReview,
  fetchReviewVoted,
  showRatingByUser,
  showReviewsUser,
  submitUpvote,
} from '../Utils/fetchData';
import { fetchMovieData } from '../Utils/fetchMovie';
import { Paginate } from '../Components';

const key = process.env.REACT_APP_API_KEY;

const Review = () => {
  class Review {
    constructor(id, title, date, message, movieId, rating) {
      this.id = id;
      this.title = title;
      this.date = date;
      this.message = message;
      this.movieId = movieId;
      this.rating = rating;
    }
  }

  class Vote {
    constructor(id, totalVote, userVote) {
      this.id = id;
      this.totalVote = totalVote;
      this.userVote = userVote;
    }
  }

  const Navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState(searchParams.get('sort') || 'latest');
  const [reviews, setReviews] = useState([]);
  const [votes, setVotes] = useState([]);
  const [pages, setPages] = useState(0);
  const page = searchParams.get('page') || 1;
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const username = JSON.parse(localStorage.getItem('user')).user.username;
  const [targetId, setTargetId] = useState('');

  const ReviewName = styled(Typography)({
    color: 'white',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: 'yellow',
    },
  });

  const ReviewText = styled(Typography)({
    color: 'white',
    fontFamily: 'Open Sans',
    textAlign: 'justify',
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
      : Math.sign(num) * Math.abs(num);
  }

  const fetchReviews = async () => {
    setReviews([]);
    try {
      const {
        data: { reviews, pages },
      } = await showReviewsUser(page, sort);
      setPages(pages);
      reviews.forEach(async (review) => {
        const results = await fetchMovieData(
          `movie/${review.movie_id}?api_key=${key}`
        );
        const date = new Date(review.updatedAt);
        const fulldate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

        const {
          data: { ratings },
        } = await showRatingByUser(review.movie_id);

        setReviews((prev) => [
          ...prev,
          new Review(
            review.id,
            results.title,
            fulldate,
            review.message,
            review.movie_id,
            ratings.value
          ),
        ]);

        const {
          data: { votes },
        } = await fetchReviewVoted(review.id);
        let userVoted;
        if (votes) userVoted = true;
        else userVoted = false;

        setVotes((prev) => [
          ...prev,
          new Vote(review.id, review.upvote, userVoted),
        ]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSort = async (e) => {
    setSort(e.target.value);
    Navigate(`?page=${page}&sort=${e.target.value}`);
  };

  const submitVote = async (id) => {
    try {
      const {
        data: { checkreview },
      } = await submitUpvote(id);

      const updatedVote = votes.find((vote) => vote.id === id);
      updatedVote.userVote = !updatedVote.userVote;
      updatedVote.totalVote = checkreview.upvote;

      const newVote = votes.filter((vote) => vote.id !== id);
      setVotes(newVote);
      setVotes((prev) => [...prev, updatedVote]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteReview = async () => {
    try {
      const res = await deleteReview(targetId);
      fetchReviews();
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDelete = (movieId) => {
    setdeleteConfirm(!deleteConfirm);
    setTargetId(movieId);
  };

  const closeDelete = () => {
    setdeleteConfirm(false);
    setTargetId('');
  };

  useEffect(() => {
    document.title = username + "'s Reviews - MovieDocs";
    fetchReviews();
  }, [sort, page]);

  return (
    <div
      style={{
        minHeight: `calc(100vh - 220px)`,
      }}
    >
      <Container>
        <Stack direction='row' justifyContent='space-between' padding={1}>
          <Typography fontSize={30} fontFamily='Poppins'>
            {username}'s Reviews
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <FormControl sx={{ width: '150px' }}>
              <InputLabel>Sort by</InputLabel>
              <Select
                value={sort}
                label='Sort by'
                onChange={(e) => {
                  handleSort(e);
                }}
              >
                <MenuItem value='popularity'>Popularity</MenuItem>
                <MenuItem value='latest'>Latest</MenuItem>
                <MenuItem value='oldest'>Oldest</MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>
        </Stack>
        <Stack mb={2}>
          {reviews.length > 0 ? (
            reviews.map((review, index) => (
              <Grid
                container
                key={index}
                sx={{
                  backgroundColor: '#2C3E50',
                  borderRadius: 2,
                  mt: 2,
                  padding: 2,
                }}
              >
                {/* <Grid xs={1} justifyContent='center' display='flex'>
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {review.username.split('')[0]}
                  </Avatar>
                </Grid> */}
                <Grid xs={12}>
                  <Stack>
                    <Grid container>
                      <Grid xs={12} md={11}>
                        <Stack
                          direction='row'
                          spacing={2}
                          mb={1}
                          sx={{ position: 'relative' }}
                          divider={
                            <Divider
                              orientation='vertical'
                              flexItem
                              color='gray'
                              light
                              variant='medium'
                            />
                          }
                        >
                          <ReviewName
                            onClick={() => {
                              Navigate(`/movie/${review.movieId}`);
                            }}
                          >
                            {review.title}
                          </ReviewName>
                          <Typography
                            fontSize='16px'
                            sx={{
                              ml: 2,
                              color:
                                review.rating < 4
                                  ? 'red'
                                  : review.rating > 7
                                  ? 'green'
                                  : 'yellow',
                              fontFamily: 'Open Sans',
                              fontWeight: 'bold',
                            }}
                          >
                            {review.rating}
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid xs={12} md={1}>
                        <Typography
                          fontSize='16px'
                          sx={{
                            ml: { xs: 0, md: 1 },
                            mb: { xs: 1, md: 0 },
                            color: 'white',
                            fontFamily: 'Open Sans',
                            fontWeight: 'bold',
                          }}
                        >
                          {review.date}
                        </Typography>
                      </Grid>

                      <Grid xs={12} sx={{ pr: { xs: 0, md: 1 } }}>
                        <ReviewText>{review.message}</ReviewText>
                      </Grid>

                      <Grid xs={12}>
                        <Stack
                          direction='row'
                          alignItems='center'
                          mt={1}
                          sx={{ position: 'relative' }}
                        >
                          <IconButton
                            onClick={() => {
                              submitVote(review.id);
                            }}
                          >
                            {votes.find((vote) => vote.id === review.id)
                              ?.userVote ? (
                              <ThumbUpAltIcon sx={{ color: '#3A7BBB' }} />
                            ) : (
                              <ThumbUpOffAltIcon sx={{ color: '#3A7BBB' }} />
                            )}
                          </IconButton>
                          <Typography>
                            {votes.find((vote) => vote.id === review.id)
                              ?.totalVote &&
                              kFormatter(
                                votes.find((vote) => vote.id === review.id)
                                  ?.totalVote
                              )}
                          </Typography>
                          <IconButton
                            onClick={() => {
                              toggleDelete(review.movieId);
                            }}
                            sx={{ position: 'absolute', right: 0 }}
                          >
                            <DeleteIcon sx={{ color: 'gray' }} />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography variant='subtitle1'>
              There are no reviews here yet...
            </Typography>
          )}
        </Stack>
        <Paginate sort={sort} page={page} total={pages} />
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={deleteConfirm}
          onClick={closeDelete}
        >
          <Paper
            sx={{
              backgroundColor: 'white',
              width: { xs: '90%', sm: '50%', md: '30%' },
              height: { xs: '25%', md: '30%' },
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography fontSize={20} fontFamily='Open Sans'>
              Are you sure? This can't be undone.
            </Typography>
            <Stack spacing={2} direction='row' mt={{ xs: 2, md: 4 }}>
              <Button onClick={handleDeleteReview} variant='contained'>
                Confirm
              </Button>
              <Button
                onClick={closeDelete}
                variant='contained'
                sx={{
                  backgroundColor: 'gray',
                  '&:hover': {
                    backgroundColor: 'gray',
                  },
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Paper>
        </Backdrop>
      </Container>
    </div>
  );
};

export default Review;
