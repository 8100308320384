import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Typography, Stack, Divider, Container } from '@mui/material';

import { fetchMovieData } from '../Utils/fetchMovie';
import { Paginate } from '../Components';

const key = process.env.REACT_APP_API_KEY;

const SearchFeed = () => {
  const [movies, setMovies] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const query = searchParams.get('query');
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    document.title = query + ' - MovieDocs';
    const fetchData = async () => {
      try {
        const { results, total_pages } = await fetchMovieData(
          `search/movie?&api_key=${key}&query=${query}&page=${page}`
        );
        setMovies(results);
        setTotalPages(total_pages);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, [query, page]);

  return (
    <>
      <Typography fontSize='14px' ml={2} fontFamily='Poppins'>
        Result for {query}
      </Typography>
      <Stack
        m={2}
        spacing={2}
        divider={<Divider orientation='horizontal' flexItem color='gray' />}
      >
        {movies.map((movie) => (
          <Stack key={movie.id} direction='row'>
            <Link
              to={`/movie/${movie.id}`}
              style={{
                backgroundImage: movie.poster_path
                  ? `url(https://image.tmdb.org/t/p/original/${movie.poster_path})`
                  : 'url(https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png)',
                width: '150px',
                height: '200px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                transition: 'ease-in-out 0.6s',
                borderRadius: 3,
                boxShadow: 5,
              }}
            />
            <Container ml={2}>
              <Link to={`/movie/${movie.id}`}>
                <Typography
                  display='inline-block'
                  sx={{
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontSize: '22px',
                    '&:hover': {
                      color: 'yellow',
                    },
                  }}
                >
                  {movie.title}
                </Typography>
              </Link>
              <Typography color='gray'>
                ({movie?.release_date?.split('-')[0]})
              </Typography>
              <Typography mt={1} color='lightgray'>
                {movie.overview}
              </Typography>
            </Container>
          </Stack>
        ))}
      </Stack>
      <Paginate query={query} page={page} total={totalPages} />
    </>
  );
};

export default SearchFeed;
