import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Stack, Typography, Divider, Button, Container } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import decode from 'jwt-decode';
import Cookies from 'js-cookie';

import AvatarMenu from './AvatarMenu';
import LeftDrawer from './Drawer';
import SearchBar from './SearchBar';
import { refreshToken } from '../Utils/fetchUser';

const Navbar = () => {
  const Navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || null
  );

  useEffect(() => {
    // const token = user?.jwtToken;

    // if (token) {
    //   const decodedToken = decode(token);

    //   if (decodedToken.exp * 1000 < new Date().getTime()) handleRefresh();
    // }
    // Cookies.set('foo', 'bar');
    // console.log(Cookies.get());
  });

  return (
    // <Container maxWidth='lg' sx={{ backgroundColor: 'blue' }}>
    <Stack
      direction='row'
      alignItems='center'
      p={2}
      sx={{
        position: 'sticky',
        top: 0,
        justifyContent: { md: 'space-between', xs: 'center' },
        zIndex: 5,
        backgroundColor: '#000030',
      }}
    >
      <LeftDrawer />

      <Typography
        variant='h5'
        sx={{
          fontFamily: 'Poppins',
          mr: { md: 30 },
        }}
      >
        <Link to='/' style={{ color: 'white' }}>
          MovieDocs
        </Link>
      </Typography>

      <Stack direction='row'>
        <SearchBar />

        <Stack
          sx={{ display: { xs: 'none', md: 'flex' } }}
          direction='row'
          alignItems='center'
          divider={
            <Divider
              orientation='vertical'
              flexItem
              color='white'
              light
              variant='middle'
            />
          }
        >
          <Link to='/trending?page=1'>
            <Typography
              variant='subtitle1'
              color='white'
              sx={{
                fontFamily: 'Poppins',
                mr: '20px',
                '&:hover': { color: 'yellow' },
              }}
            >
              Trending
            </Typography>
          </Link>
          <Link to='/playing?page=1'>
            <Typography
              variant='subtitle1'
              color='white'
              sx={{
                fontFamily: 'Poppins',
                mr: '20px',
                ml: '20px',
                '&:hover': { color: 'yellow' },
              }}
            >
              Now Playing
            </Typography>
          </Link>
          <Link to='/upcoming?page=1'>
            <Typography
              variant='subtitle1'
              color='white'
              sx={{
                fontFamily: 'Poppins',
                mr: '20px',
                ml: '20px',
                '&:hover': { color: 'yellow' },
              }}
            >
              Coming Soon
            </Typography>
          </Link>
        </Stack>

        {user ? (
          <AvatarMenu />
        ) : (
          <Link to='/login'>
            <Button
              sx={{
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                fontSize: '16px',
                right: '10px',
              }}
            >
              Login
            </Button>
            <LoginIcon
              sx={{
                display: { md: 'none' },
                position: 'absolute',
                right: '20px',
                top: '20px',
                color: 'white',
              }}
            />
          </Link>
        )}
      </Stack>
    </Stack>
    // </Container>
  );
};

export default Navbar;
