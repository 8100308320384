import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { deleteUser, showUser } from '../Utils/fetchData';
import { logout } from '../Utils/fetchUser';
import {
  Typography,
  FormControl,
  InputLabel,
  IconButton,
  Button,
  Snackbar,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import bcrypt from 'bcryptjs'

const UserDelete = () => {
  const [pass, setPassword] = useState("");
  const [showPass, setShowPassword] = useState("");
  const [err, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUser] = useState({});
  const [message, setMessage] = useState("");
  const Navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkPass = async (passw) => {
    const checkPassword = await bcrypt.compare(passw, userData.password);

    if (checkPassword){
      return true
    }
    else{
      return false
    }
  }

  const handleLogout = async () => {
    localStorage.removeItem('user');
    Navigate('/');
    window.location.reload();
  };

  const handleDelete = async () => {
    try {
      const reslogout = await logout();
      try {
        const res = await deleteUser(user.user.id);
        
        if (res.status === 201){
          handleLogout();
        }
        else{
          setMessage("Something wrong. Try again.")
          setError(true)
          setOpen(true)
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }

    
  };

  const fetchData = async () => {
    try {
      const res = await showUser();
      setUser(res.data.user)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = 'Delete Account - MovieDocs';
    fetchData();
  }, []);

  return (
    <div
      style={{
        minHeight: `calc(100vh - 220px)`,
      }}
    >
        <Grid container>
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
            <Alert
                onClose={handleClose}
                severity={err ? 'error' : 'success'}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>

        {/* start */}
        <Grid xs={3} md={3}>
        </Grid>

        <Grid container xs={6} md={6} sx={{
            backgroundColor: '#EFEFEF',
            borderRadius: '10px',
            
        }}>
          <Grid xs={6} md={6} xsOffset={3} mdOffset={3} direction="row" className='userValue' sx={{
            justifyContent: "center",
            display: "flex",
            mt: '20px'
          }}>
            <Typography align='justify' sx={{
                color: 'black',
                fontFamily: 'Outfit',
                fontSize: '20px',
                fontWeight: 'bold',
              }}>
              By deleting this account means you are agreeing on destroying every activities you have done with this account and no longer be able to retrieve or access anything from or using this account.
            </Typography>
          </Grid>
          <Grid xs={6} md={6} xsOffset={3} mdOffset={3} direction="row" className='userValue' sx={{
            justifyContent: "center",
            display: "flex",
            mt: '10px',
            mb: '10px'
          }}>
            <FormControl
              variant='outlined'
            >
              <InputLabel htmlFor='outlined-adornment-password'>
                Confirm Password
              </InputLabel>
              <OutlinedInput
                  id='password'
                  type={showPass ? 'text' : 'password'}
                  value={pass}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  focused='true'
      
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Confirm Password'
                />
            </FormControl>
            {/* <TextField
                label='Confirm Password'
                id='password'
                value={pass}
                onChange={(e) => {setPassword(e.target.value)}}
            /> */}
          </Grid>
          <Grid xs={6} xsOffset={3} md={6} mdOffset={3} sx={{
            textAlign: 'center'
          }}>
            <Button
              variant='contained'
              onClick={async () => {
                let status = await checkPass(pass)
                if (status){
                  const confirmBox = window.confirm(
                    "By confirming this, you are agreeing on deleting this account and knowing the consequences as written before deletion. Are you sure this is what you wanted? after everything happened?"
                  )
                  if (confirmBox === true) {
                    handleDelete()
                  }
                  else{
                    return
                  }
                }
                else{
                  setMessage("Wrong Password")
                  setError(true)
                  setOpen(true)
                }
              }}
              sx={{ mt: 1, mb: 3}}
            >
              Delete Account
            </Button>
          </Grid>
        </Grid>
        <Grid xs={3} md={3}>
        </Grid>
        {/* end */}
        </Grid>
    </div>
  );
};

export default UserDelete;
