import axios from 'axios';

const BASE_URL = 'https://api.themoviedb.org/3';

// export const fetchMovie = async (url) => {
//   const { data } = await axios.get(`${BASE_URL}/${url}`);

//   return data;
// };

// export const fetchMovieTrailer = async (url) => {
//   const { data } = await axios.get(`${BASE_URL}/${url}`);

//   return data;
// };

// export const fetchCredit = async (url) => {
//   const { data } = await axios.get(`${BASE_URL}/${url}`);
//   return data;
// };

// export const fetchRecommendation = async (url) => {
//   const { data } = await axios.get(`${BASE_URL}/${url}`);
//   return data;
// };

export const fetchMovieData = async (url) => {
  const { data } = await axios.get(`${BASE_URL}/${url}`);
  return data;
};


