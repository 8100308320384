import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import { Search } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

export default function LeftDrawer() {
  const Navigate = useNavigate();

  const [state, setState] = React.useState({
    left: false,
  });

  const [searchTerm, setSearchTerm] = React.useState('');

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: 250,
      }}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider sx={{ mt: '15px' }} />
      <List>
        <ListItem disablePadding>
          <Link to='/trending' style={{ color: 'black' }}>
            <ListItemButton>
              <ListItemIcon>
                <WhatshotIcon />
              </ListItemIcon>
              <ListItemText primary='Trending' />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link to='/playing?page=1' style={{ color: 'black' }}>
            <ListItemButton>
              <ListItemIcon>
                <PlayCircleIcon />
              </ListItemIcon>
              <ListItemText primary='Now Showing' />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link to='/upcoming?page=1' style={{ color: 'black' }}>
            <ListItemButton>
              <ListItemIcon>
                <UpcomingIcon />
              </ListItemIcon>
              <ListItemText primary='Coming Soon' />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchTerm) {
      Navigate(`/search?page=1&query=${searchTerm}`);
    }

    setSearchTerm('');
    setState({ ...state, left: false });
  };

  return (
    <div>
      <Button
        onClick={toggleDrawer('left', true)}
        sx={{
          position: { xs: 'absolute', md: 'relative' },
          left: '0px',
          top: '15px',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <MenuIcon />
      </Button>
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: 1.5, mt: 1 }}>
            <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              label='Search'
              variant='standard'
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </Box>
        </form>
        {list('left')}
      </Drawer>
    </div>
  );
}
