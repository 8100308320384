import React from 'react';
import { Link } from 'react-router-dom';
import { PaginationItem, Pagination } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const Paginate = ({ query, page, total, sort }) => {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const path = window.location.pathname;

  return (
    <ThemeProvider theme={darkTheme}>
      <Pagination
        count={total}
        page={+page}
        color='primary'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            component={Link}
            to={`${path}?page=${item.page}` + (query ? `&query=${query}` : '') + (sort ? `&sort=${sort}` : '')}
          />
        )}
      />
    </ThemeProvider>
  );
};

export default Paginate;
