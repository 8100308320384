import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { RegisterBox } from '../Components';

const Login = () => {
  document.title = 'Register - MovieDocs';
  return (
    <Grid container>
      <Grid xs={12}>
        <RegisterBox />
      </Grid>
    </Grid>
  );
};

export default Login;
