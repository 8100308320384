import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Stack } from '@mui/system';
import { Button, FormHelperText, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

// import { login } from '../Utils/fetchUser';
import { forgotPass, resubmit } from '../Utils/fetchData';

export default function ForgotPassBox() {
  const Navigate = useNavigate();

  const delayTime = 1 * 60;

  const [error, setError] = React.useState(false);
  const [falseInfo, setFalseInfo] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const [timer, setTimer] = React.useState(delayTime);
  const [timerEnabled, setTimerEnabled] = React.useState(false);

  const TimerStart = () => {
    let interval = setInterval(() => {
      if (timer <= 1) {
        setDisabled(false);
        setTimerEnabled(false);
        setTimer(delayTime);
      } else setTimer(timer - 1);

      clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  };

  const validateEmail = (email) => {
    if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    alert('You have entered an invalid email address!');
    return false;
  };

  const handleForgot = async (e) => {
    if (email === '') {
      setError(true);
      setFalseInfo(false);
      setSuccess(false);
      return;
    }
    const validEmail = validateEmail(email);

    if (!validEmail) return;

    try {
      const {
        data: { message, time },
      } = await resubmit(email);

      if (message == 'true'){
        setError(false);
        setFalseInfo(true);
        setSuccess(false);
        setMessage('You have sent an email, Please wait for remaining time before submitting again');
        // Timer
        setTimer(time);
        setDisabled(true);
        setTimerEnabled(true);
      }
      else{
        // Timer
        setDisabled(true);
        setTimerEnabled(true);

        const res = await forgotPass({
          email,
        });

        if (res.status === 200) {
          setError(false);
          setFalseInfo(false);
          setSuccess(true);
        } else if (res.status === 400) {
          setError(false);
          setFalseInfo(true);
          setSuccess(false);
          setMessage('Wrong email');
        } else {
          setError(false);
          setFalseInfo(true);
          setSuccess(false);
          setMessage('Email not sent. Please re-check your email address');
        }
      }
      
    } catch (error) {
      setError(false);
      setFalseInfo(true);
      setSuccess(false);
      setMessage(error.response.data.error);
    }
  };

  React.useEffect(() => {
    if (timerEnabled) {
      TimerStart();
    };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Stack
        direction='column'
        sx={{
          height: { xs: '100vh', md: '60vh' },
          width: { xs: '100vw', md: '25vw' },
          backgroundColor: '#EFEFEF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          border: '1px solid black',
          boxShadow: 5,
        }}
      >
        <Typography
          color='black'
          sx={{
            backgroundColor: '#369BFB',
            width: '100%',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            fontSize: '24px',
            fontFamily: 'Poppins',
            mb: 3,
            top: 0,
            position: 'absolute',
          }}
        >
          Forgot Password
        </Typography>
        <Typography
          align='justify'
          sx={{
            width: '30ch',
            color: 'black',
            fontFamily: 'Outfit',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '20px',
          }}
        >
          Enter the email address used when this account was created, The next
          step will be sent to the email address associated.
        </Typography>
        <form onSubmit={(e) => handleForgot()}>
          <FormControl sx={{ width: '30ch' }} variant='outlined'>
            <InputLabel htmlFor='outlined-adornment-password'>
              Email Address
            </InputLabel>

            <OutlinedInput
              id='email'
              type='text'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              label='Email Address'
            />
            <FormHelperText>
              {error
                ? 'Email Address cannot be empty'
                : falseInfo
                ? message
                : success
                ? 'Confirmation Request has been sent to your email'
                : ''}
            </FormHelperText>
          </FormControl>
        </form>

        <Button
          variant='contained'
          onClick={handleForgot}
          sx={{ mt: 1, mb: 1 }}
          disabled={disabled}
        >
          {disabled ? `Send Again (${timer})` : 'Submit'}
        </Button>
        <Typography
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            bottom: { xs: '10px', md: '20px' },
          }}
        >
          <Link to='/login' style={{ color: '#2587BB', fontFamily: 'Poppins' }}>
            Sign in
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
