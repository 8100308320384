import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { ForgotPassBox } from '../Components';

const ForgotPass = () => {
  document.title = 'Forgot Pass - MovieDocs';
  return (
    <Grid container>
      <Grid xs={12}>
        <ForgotPassBox />
      </Grid>
    </Grid>
  );
};

export default ForgotPass;