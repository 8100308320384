import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MovieCards } from '../Components';
import { showFavoriteByUser, submitFavorite } from '../Utils/fetchData';
import { fetchMovieData } from '../Utils/fetchMovie';
import {
  Container,
  Paper,
  Stack,
  Typography,
  IconButton,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import FavoriteIcon from '@mui/icons-material/Favorite';

import Paginate from '../Components/Pagination';

import Grid from '@mui/material/Unstable_Grid2';

const key = process.env.REACT_APP_API_KEY;

const Favourite = () => {
  class Favourite {
    constructor(id, userid, movieid, image, inList) {
      this.id = id;
      this.userid = userid;
      this.movieid = movieid;
      this.image = image;
      this.inList = inList;
    }
  }

  const Navigate = useNavigate();
  const [favouriteData, setFav] = useState([]);
  const [imgStr, setImg] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState('');
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const page = searchParams.get('page') || 1;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const handleFavourite = async (movieid) => {
    try {
      const res = await submitFavorite(movieid);
      const message = res.data.message;
      if (message === 'Favourite Removed !')
        setAlertText('Removed from favourite!');
      else setAlertText('Added to favourite!');

      if (open === true) setOpen(false);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const fetchMovie = async (movieid) => {
    try {
      const results = await fetchMovieData(`movie/${movieid}?api_key=${key}`);
      return `${results.poster_path}`;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const {
        data: { favourites, pages },
      } = await showFavoriteByUser(page);
      setTotalPages(pages);

      favourites.forEach(async (fav) => {
        const image = await fetchMovie(fav.movie_id);
        setFav((prev) => [
          ...prev,
          new Favourite(fav.id, fav.user_id, fav.movie_id, image, true),
        ]);
      });

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = 'Favorite - MovieDocs';
    const getUser = JSON.parse(localStorage.getItem('user'));
    setUser(getUser);
    setFav([]);
    fetchData();
    window.scrollTo(0, 0);
  }, [page]);

  // if (favouriteData.length < 1) return null;

  return (
    <>
      <div
        style={{
          minHeight: `calc(100vh - 220px)`,
        }}
      >
        <Container sx={{ width: '100%', height: '100%' }}>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: '100%' }}
            >
              {alertText}
            </Alert>
          </Snackbar>

          <Stack
            direction='row'
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 3,
            }}
          >
            {favouriteData.map((fav) => (
              <div key={fav.id} style={{ position: 'relative' }}>
                <Container
                  key={fav.id}
                  elevation={5}
                  sx={{
                    m: 1,
                    position: 'relative',
                    display: 'flex',
                    width: { xs: '100px', md: '200px' },
                    height: { xs: '200px', md: '300px' },
                    backgroundImage: fav.image
                      ? `url(https://image.tmdb.org/t/p/original${fav.image})`
                      : 'url(https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    transition: 'ease-in-out 0.4s',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.0)',
                    },
                  }}
                  onClick={() => {
                    Navigate(`/movie/${fav.movieid}`);
                  }}
                ></Container>
                <IconButton
                  onClick={() => {
                    if (!user) return Navigate('/login');
                    //update db
                    handleFavourite(fav.movieid);

                    //change target favorite condition (fav or not)
                    const changeList = favouriteData.find(
                      (change) => change.id === fav.id
                    );
                    changeList.inList = !changeList.inList;

                    // //get all other favorite datas other than target
                    // const newFavData = newFavData.filter(
                    //   (newdata) => newdata.id !== fav.id
                    // );
                    // //set it as new data
                    // setFav(newFavData);

                    // //add in updated target favorite data
                    // setFav((favData) => [...favData, changeList]);
                  }}
                  component='label'
                  title={
                    fav.inList ? 'Remove from favourite' : 'Add to favourite'
                  }
                  sx={{
                    color: fav.inList ? 'red' : 'gray',
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    margin: 2,
                    opacity: 1,
                    '&:hover': {
                      opacity: 1,
                      backgroundColor: 'white',
                      transform: 'scale(1.1)',
                    },
                  }}
                >
                  <FavoriteIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </div>
            ))}
          </Stack>
          
          {favouriteData.length > 0 ? <Paginate page={page} total={totalPages} /> : <></>}
        </Container>
      </div>
    </>
  );
};

export default Favourite;
