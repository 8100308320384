import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Stack,
  Card,
  CardActions,
  CardMedia,
  Button,
  Typography,
  styled,
  Rating,
  IconButton,
  Container,
  Snackbar,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MuiAlert from '@mui/material/Alert';
import LinkIcon from '@mui/icons-material/Link';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { fetchMovieData } from '../Utils/fetchMovie';
import {
  submitRating,
  showRatingByUser,
  submitWatchlist,
  showWatchlists,
  showFavorite,
  submitFavorite,
} from '../Utils/fetchData';
import { VideoLayer, ReviewBox, Cast, MovieSlide } from '../Components';

const key = process.env.REACT_APP_API_KEY;

const BelowTitle = styled(Typography)({
  fontFamily: 'Outfit',
  letterSpacing: '1px',
  fontSize: '16px',
});

const TrivialStack = styled(Stack)({
  fontFamily: 'Open Sans',
  fontWeight: 'bold',
  width: 'calc(100% / 3)',
  marginBottom: '30px',
});

const TrivialTypo = styled(Typography)({
  mt: '5px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
});

const MovieDetail = () => {
  const Navigate = useNavigate();
  const [movie, setMovie] = useState(null);
  const [video, setVideo] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const [value, setValue] = useState(0);
  const [rate, setRate] = useState(false);
  const [watchlists, setWatchlists] = useState(false);
  const [userWatchlists, setuserWatchlists] = useState(null);
  const [alertText, setAlertText] = useState('');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [videoId, setVideoId] = useState(null);
  const [checkList, setCheckList] = useState(false);
  const [favorite, setFavorite] = useState(false);
  let { id } = useParams();

  const fetchUserData = async () => {
    try {
      const { data } = await showRatingByUser(id);
      if (data.ratings) setValue(data.ratings.value);

      const {
        data: { watchlists },
      } = await showWatchlists();
      // if (watchlists.length > 0) {
      if (user) {
        setuserWatchlists(watchlists);
        if (watchlists.find((watchlist) => watchlist.movie_id == id)) {
          setWatchlists(true);
        }
        setCheckList(true);
      } else {
        setCheckList(true);
      }

      const {
        data: { favourites },
      } = await showFavorite();
      if (favourites.length > 0) {
        if (favourites.find((favourite) => favourite.movie_id == id))
          setFavorite(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMovie = async () => {
    try {
      const results = await fetchMovieData(`movie/${id}?api_key=${key}`);
      setStatus(results.status);
      setMovie(results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleRating = async () => {
    if (value === 0) return;
    try {
      await submitRating({ value: value }, id);
      setAlertText('Rating Saved!');
      setOpen(true);
      setRate(false);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleWatchlist = async () => {
    try {
      const res = await submitWatchlist({ title: movie?.title }, id);
      const message = res.data.message;
      if (message === 'Watchlist Removed !')
        setAlertText('Removed from watchlist!');
      else setAlertText('Added to watchlist!');
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLove = async () => {
    try {
      await submitFavorite(id);
      setFavorite((favorite) => !favorite);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMovie();
    if (user) fetchUserData();
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  if (movie === null) return null;
  if (user && !checkList) return null;

  document.title = movie?.title + ' - MovieDocs';

  return (
    <>
      {video && (
        <VideoLayer
          id={videoId}
          close={() => {
            setVideo(false);
          }}
        />
      )}

      <Container
        maxWidth='false'
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          height: { xs: '100%', md: '100vh', lg: '85vh' },
          width: { xs: '100%', md: '100%' },
          backgroundImage: `url(https://image.tmdb.org/t/p/original/${movie?.backdrop_path})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: { xs: 'center', md: '300px 0px' },
          backgroundSize: 'cover',
          borderRadius: { xs: 0, md: 2 },
          transition: 'ease-in-out 0.6s',
          '&::after': {
            position: 'absolute',
            left: 0,
            content: '""',
            backgroundImage:
              'linear-gradient(to right, black , rgba(0, 0, 0, 1))',
            width: '100%',
            height: 'inherit',
            opacity: 0.8,
            zIndex: 0,
          },
        }}
      >
        <Container
          sx={{
            backgroundImage:
              'linear-gradient(to right, black , rgba(0, 0, 0, 0.0))',
            left: '0%',
            width: '90%',
            height: '100%',
            zIndex: 0,
            position: 'absolute',
          }}
        ></Container>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: '100%' }}
          alignItems='center'
        >
          <Card
            sx={{
              width: '300px',
              zIndex: 2,
              border: 'none',
              padding: 'none',
              backgroundColor: '#063970',
              m: 3,
            }}
          >
            <CardMedia
              component='img'
              height='450px'
              image={`https://image.tmdb.org/t/p/original/${movie?.poster_path}`}
            />
            <CardActions>
              <Button
                sx={{
                  width: '100%',
                  height: '25px',
                  backgroundColor: '#063970',
                  color: 'white',
                  fontFamily: 'Poppins',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#063970',
                  },
                }}
                onClick={() => (window.location.href = `${movie?.homepage}`)}
              >
                Homepage <LinkIcon />
              </Button>
            </CardActions>
          </Card>

          <Container
            sx={{ zIndex: 2, ml: 1, width: { xs: '100%', md: '70%' } }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                display: 'flex',
                alignItems: { xs: 'flex-start', md: 'center' },
              }}
              spacing={{ xs: 1, md: 2 }}
            >
              <Typography sx={{ fontFamily: 'Noto Serif', fontSize: '35px' }}>
                {movie?.title}
              </Typography>
              <Typography
                fontSize='30px'
                fontFamily='Open Sans'
                sx={{ opacity: 0.8, ml: 1 }}
              >
                ({movie?.release_date.split('-')[0]})
              </Typography>

              <Typography
                align='center'
                sx={{
                  width: '35px',
                  ml: 3,
                  border: '2px solid #063970',
                  padding: '3px',
                  borderRadius: 2,
                  fontFamily: 'Outfit',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                {Math.round(movie?.vote_average * 10) / 10}
                {Math.round(movie?.vote_average * 10) % 10 === 0 && '.0'}
              </Typography>
            </Stack>

            <Stack direction={{ xs: 'column', md: 'row' }}>
              <BelowTitle>
                {`${movie?.release_date.split('-')[2]}/${
                  movie?.release_date.split('-')[1]
                }/${movie?.release_date.split('-')[0]}`}
              </BelowTitle>
              <ul
                style={{
                  margin: 0,
                  padding: 0,
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <li style={{ marginLeft: '30px' }}>
                  <BelowTitle>
                    {movie?.genres.map((genre, index) => {
                      if (index <= movie.genres.length - 2)
                        return genre.name + ',';
                      else return genre.name;
                    })}
                  </BelowTitle>
                </li>
                <li style={{ marginLeft: '30px' }}>
                  <BelowTitle>
                    {Math.floor(movie?.runtime / 60)}h {movie?.runtime % 60}m
                  </BelowTitle>
                </li>
              </ul>
            </Stack>

            <Stack>
              <Typography
                sx={{
                  opacity: 0.7,
                  fontFamily: 'Open Sans',
                  fontStyle: 'italic',
                  mt: 3,
                }}
              >
                {movie?.tagline}
              </Typography>

              <Typography
                sx={{
                  m: '10px 0',
                  fontFamily: 'Open Sans',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                Overview
              </Typography>

              <Typography align='justify' sx={{ fontFamily: 'Open Sans' }}>
                {movie?.overview}
              </Typography>
            </Stack>

            <Stack
              direction='row'
              sx={{ mt: 3, display: 'flex', flexWrap: 'wrap' }}
            >
              <TrivialStack>
                Status
                <TrivialTypo>{movie?.status}</TrivialTypo>
              </TrivialStack>
              <TrivialStack>
                Budget
                <TrivialTypo>
                  {movie?.budget > 0 ? formatter.format(movie.budget) : '-'}
                </TrivialTypo>
              </TrivialStack>
              <TrivialStack>
                Revenue
                <TrivialTypo>
                  {movie?.revenue > 0 ? formatter.format(movie.revenue) : '-'}
                </TrivialTypo>
              </TrivialStack>
              <TrivialStack>
                Company
                <TrivialTypo>
                  {movie?.production_companies[0]?.name || '-'}
                </TrivialTypo>
              </TrivialStack>
            </Stack>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              flexWrap={{ xs: 'wrap', md: 'none' }}
              spacing={{ xs: 2 }}
              position={{ xs: 'relative', md: 'relative' }}
              mb={1}
              alignItems='center'
            >
              <Button
                variant='outlined'
                startIcon={<PlayArrowIcon />}
                onClick={() => {
                  setVideoId(id);
                  setVideo(true);
                }}
              >
                Play Trailer
              </Button>

              <Button
                variant='outlined'
                startIcon={!watchlists ? <AddIcon /> : <RemoveIcon />}
                onClick={() => {
                  if (!user) Navigate('/login');
                  else {
                    setWatchlists((prev) => !prev);
                    handleWatchlist();
                  }
                }}
                sx={[
                  { ml: 2 },
                  watchlists && {
                    color: 'red',
                    border: 'red 1px solid',
                    opacity: 0.9,
                    '&:hover': {
                      border: 'red 1px solid',
                      opacity: 1,
                    },
                  },
                ]}
              >
                Watchlist
              </Button>

              {user && rate ? (
                <Stack direction='row' alignItems='center'>
                  <Rating
                    value={value / 2}
                    onChangeActive={(e) => {}}
                    onChange={(e) => {
                      setValue(parseFloat(e.target.value) * 2);
                    }}
                    precision={0.5}
                    size='large'
                    sx={{ ml: 5 }}
                    emptyIcon={
                      <StarBorderIcon
                        fontSize='inherit'
                        sx={{ opacity: 0.7, color: 'white', zIndex: -1 }}
                      />
                    }
                  />
                  <IconButton
                    onClick={handleRating}
                    component='label'
                    sx={{
                      color: 'blue',
                      backgroundColor: 'white',
                      ml: 2,
                      '&:hover': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    <CheckIcon sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Stack>
              ) : (
                <Button
                  disabled={status !== 'Released' && true}
                  startIcon={value !== 0 && <CheckIcon />}
                  variant='outlined'
                  sx={[
                    {
                      ml: 2,
                    },
                    value !== 0 && {
                      color: 'green',
                      border: 'green 1px solid',
                      opacity: 0.9,
                      '&:hover': {
                        border: 'green 1px solid',
                        opacity: 1,
                      },
                    },
                  ]}
                  onClick={() => {
                    if (!user) Navigate('/login');
                    setRate(true);
                  }}
                >
                  {value === 0 ? 'Rate' : 'Rated'}
                </Button>
              )}

              <IconButton
                onClick={() => {
                  if (!user) Navigate('/login');
                  else handleLove();
                }}
                component='label'
                sx={{
                  color: favorite ? 'red' : 'gray',
                  backgroundColor: 'white',
                  ml: 2,
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                  width: '40px',
                }}
              >
                <FavoriteIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Stack>
          </Container>
        </Stack>
      </Container>

      <Grid container spacing={2}>
        <Grid xs={12} md={8}>
          <ReviewBox id={id} user={user ? user : {}} rating={value} />
        </Grid>

        <Grid xs={12} md={4}>
          <Cast id={id} />
        </Grid>

        <Grid xs={12} sx={{ mt: { xs: 50, md: 0 } }}>
          <MovieSlide
            id={id}
            user={user}
            title='Recommendations'
            watchlists={userWatchlists ? userWatchlists : ''}
            onClick={(id) => {
              setVideoId(id);
              setVideo(true);
            }}
            throwId={(id) => {
              Navigate(`/movie/${id}`);
              window.location.reload();
            }}
          />
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          {alertText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MovieDetail;
