import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import {
  Home,
  Login,
  MovieDetail,
  Register,
  Trending,
  NowPlaying,
  Upcoming,
  SearchFeed,
  Profile,
  Review,
  UserReviews,
  Favourite,
  NoPage,
  Watchlist,
  UserDelete,
  ForgotPass,
  ChangePass,
} from './Pages';
import { Navbar, Footer } from './Components';

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/movie/:id',
        element: <MovieDetail />,
      },
      {
        path: '/trending',
        element: <Trending />,
      },
      {
        path: '/playing',
        element: <NowPlaying />,
      },
      {
        path: '/upcoming',
        element: <Upcoming />,
      },
      {
        path: '/search',
        element: <SearchFeed />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/review/:id',
        element: <Review />,
      },
      {
        path: '/reviews',
        element: <UserReviews />,
      },
      {
        path: '/favourite',
        element: <Favourite />,
      },
      {
        path: '*',
        element: <NoPage />,
      },
      {
        path: '/watchlist',
        element: <Watchlist />,
      },
      {
        path: '/user_delete',
        element: <UserDelete />,
      },
      // {
      //   path: '/edit_avatar',
      //   element: <EditAvatar />,
      // },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/forgot-pass',
    element: <ForgotPass />,
  },
  {
    path: '/change-pass',
    element: <ChangePass />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
