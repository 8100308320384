import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { LoginBox } from '../Components';

const Login = () => {
  document.title = 'Login - MovieDocs';
  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <LoginBox />
      </Grid>
    </Grid>
  );
};

export default Login;
