import React, { useState } from 'react';
import { Paper, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const Navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchTerm) {
      Navigate(`/search?page=1&query=${searchTerm}`);
    }

    // setSearchTerm('');
  };

  return (
    <Paper
      component='form'
      onSubmit={handleSubmit}
      sx={{
        borderRadius: 20,
        border: '1px solid #e3e3e3',
        pl: 2,
        // ml: '250px',
        boxShadow: 'none',
        mr: { sm: 5 },
        bgcolor: 'transparent',
        display: { xs: 'none', md: 'flex' },
      }}
    >
      <input
        type='text'
        className='searchBar'
        placeholder='Search here...'
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      <IconButton type='submit'>
        <Search sx={{ color: 'white' }} />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
