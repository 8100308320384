import React, { useState, useEffect } from 'react';

import { Newsletter, VideoLayer, MovieSlide } from '../Components';
import { showWatchlists } from '../Utils/fetchData';

const Home = () => {
  const [video, setVideo] = useState(false);
  const [id, setId] = useState('');
  const [watchlists, setWatchlists] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));

  const showTrailer = (key) => {
    setId(key);
    setVideo((prev) => !prev);
  };

  useEffect(() => {
    document.title = 'MovieDocs';
    const fetchData = async () => {
      if (user) {
        try {
          const {
            data: { watchlists },
          } = await showWatchlists();
          setWatchlists(watchlists);
        } catch (error) {
          console.log(error);
        }
      } else setWatchlists([]);
    };
    fetchData();
  }, []);

  if (watchlists === null) return null;

  return (
      <div
        style={{
          minHeight: `calc(100vh - 220px)`,
        }}
      >
        {video && (
          <VideoLayer
            id={id}
            close={() => {
              setVideo(false);
            }}
          />
        )}
        <Newsletter onClick={showTrailer} />

        <MovieSlide
          title='In Theather'
          onClick={showTrailer}
          watchlists={watchlists}
          user={user}
        />
        <MovieSlide
          title='Coming Soon'
          onClick={showTrailer}
          watchlists={watchlists}
          user={user}
        />
      </div>
  );
};

export default Home;
