import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MovieCards } from '../Components';
import { fetchMovieData } from '../Utils/fetchMovie';

const key = process.env.REACT_APP_API_KEY;

const Upcoming = () => {
  const [movies, setMovies] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');

  useEffect(() => {
    document.title = 'Coming Soon - MovieDocs';
    const fetchData = async () => {
      try {
        const { results, total_pages } = await fetchMovieData(
          `movie/upcoming?api_key=${key}&language=en-US&page=${page}`
        );
        setTotalPages(total_pages);
        setMovies(results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, [page]);

  if (movies.length < 1) return null;

  return (
    <>
      <MovieCards
        movies={movies}
        title='Coming Soon'
        total={totalPages}
        page={page}
      />
    </>
  );
};

export default Upcoming;
