import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { fetchMovieData } from '../Utils/fetchMovie';
import { Stack } from '@mui/system';

const key = process.env.REACT_APP_API_KEY;

const Newsletter = ({ onClick }) => {
  const [movies, setMovies] = useState([]);
  const [id, setId] = useState([]);
  const [idx, setIdx] = useState(0);
  const [timer, setTimer] = useState(0);

  // Timer
  useEffect(() => {
    let interval = setInterval(() => {
      setTimer(timer + 1);
      if (timer >= 5) {
        if (idx === 4) setIdx(0);
        else setIdx(idx + 1);
        setTimer(0);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  // Fetch Movie
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { results } = await fetchMovieData(
          `trending/movie/day?api_key=${key}`
        );
        setMovies(results);
        setId(results.slice(0, 5).map((movie) => movie.id));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleClick = () => {
    onClick(id[idx]);
  };

  if (movies.length === 0) return null;

  return (
    <>
      <Container
        sx={{
          position: 'relative',
          height: { xs: '80vh', md: '80vh' },
          width: { xs: '100%', md: '100%' },
          backgroundImage: `url(https://image.tmdb.org/t/p/original/${movies[idx]?.backdrop_path})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          backgroundSize: 'cover',
          borderRadius: { xs: 0, md: 2 },
          transition: 'ease-in-out 0.6s',
          '&::after': {
            position: 'absolute',
            left: 0,
            content: '""',
            backgroundColor: 'black',
            width: '100%',
            height: 'inherit',
            opacity: 0.3,
          },
        }}
      >
        <Container sx={{ position: 'absolute', zIndex: 1 }}>
          <Stack>
            <Link to={`/movie/${movies[idx]?.id}`}>
              <Typography
                mt={2}
                fontSize={{ xs: '20px', md: '30px' }}
                fontFamily='Poppins'
                color='white'
                display='inline-block'
                sx={{
                  '&:hover': {
                    color: '#F4D03F',
                  },
                }}
              >
                {movies[idx]?.title}
              </Typography>
            </Link>

            <Button
              variant='outlined'
              startIcon={<PlayArrowIcon />}
              onClick={handleClick}
              sx={{
                width: '185px',
                color: 'white',
                border: 'none',
                fontSize: { xs: '12px', md: '16px' },
                '&:hover': {
                  backgroundColor: '#212F3D',
                  color: '#3498DB',
                  border: 'none',
                  boxShadow: 2,
                },
              }}
            >
              Watch trailer
            </Button>
          </Stack>
        </Container>
      </Container>

      <Stack
        direction='row'
        gap={2}
        marginTop={2}
        paddingLeft={5}
        paddingRight={5}
        sx={{
          display: { xs: 'none', md: 'flex' },
        }}
      >
        {movies.slice(0, 5).map((movie, index) => (
          <Container
            key={movie.id}
            onClick={() => {
              setIdx(index);
              setTimer(0);
            }}
            sx={[
              {
                position: 'relative',
                height: { xs: '30vh', md: '20vh' },
                width: { xs: '90%', md: '40%' },
                backgroundImage: `url(https://image.tmdb.org/t/p/original/${movie?.backdrop_path})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top',
                backgroundSize: 'cover',
                borderRadius: 2,
                transition: 'ease-in-out 0.6s',
                opacity: 0.4,
                '&:hover': {
                  opacity: 1,
                },
              },
              idx === index && {
                opacity: 1,
              },
            ]}
          ></Container>
        ))}
      </Stack>
    </>
  );
};

export default Newsletter;
