import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { logout, refreshToken } from './fetchUser';
const api_url = process.env.REACT_APP_MOVIEDOCS_API;

let user = JSON.parse(localStorage.getItem('user'));
let token = JSON.parse(localStorage.getItem('user'))?.jwtToken;
const API = axios.create({
  baseURL: api_url+'/api/',
  // headers: { authorization: 'Bearer ' + token },
});

API.interceptors.request.use(
  async function (config) {
    if (user) {
      let currentDate = new Date();
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        try {
          const res = await refreshToken();
          localStorage.setItem('user', JSON.stringify(res.data));
        } catch (error) {
          console.log(error);
          try {
            const res = await logout();
          } catch (error) {
            console.log(error);
          }
          localStorage.removeItem('user');
          window.location.reload();
        }
      }

      token = JSON.parse(localStorage.getItem('user'))?.jwtToken;
      config.headers['authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const showRating = async (movieId) => {
  const res = await API.get(`ratings/${movieId}`);
  return res;
};

export const submitRating = async (value, movieId) => {
  const res = await API.post(`ratings/${movieId}`, value);
  return res;
};

export const showWatchlists = async () => {
  const res = await API.get(`watchlists`);
  return res;
};

export const showWatchlistsWithPage = async (page, filter, search) => {
  const res = await API.get(
    `watchlists/getAll?page=${page}&filter=${filter}&query=${search}`
  );
  return res;
};

export const submitWatchlist = async (title, movieId) => {
  const res = await API.post(`watchlists/${movieId}`, title);
  return res;
};

export const updateWatchlist = async (movieId) => {
  const res = await API.put(`watchlist/edit/${movieId}`);
  return res;
};

export const showReviews = async (movieId) => {
  const res = await API.get(`reviews/getByMovie/${movieId}`);
  return res;
};

export const showReviewsUser = async (page, sort) => {
  const res = await API.get(`reviews/getByUser?page=${page}&sort=${sort}`);
  return res;
};

export const showAll = async (movieId, page, sort) => {
  const res = await API.get(
    `reviews/getAll/${movieId}?page=${page}&sort=${sort}`
  );
  return res;
};

export const submitReviews = async (message, movieId) => {
  const res = await API.post(`reviews/${movieId}`, message);
  return res;
};

export const deleteReview = async (movieId) => {
  const res = await API.delete(`reviews/${movieId}`);
  return res;
};

export const showRatingByUser = async (movieId) => {
  const res = await API.get(`ratings/u/${movieId}`);
  return res;
};

export const submitFavorite = async (movieId) => {
  const res = await API.post(`favourites/${movieId}`);
  return res;
};

export const showFavorite = async () => {
  const res = await API.get(`favourites`);
  return res;
};

export const showFavoriteByUser = async (page) => {
  const res = await API.get(`favourite/detail?page=${page}`);
  return res;
};

export const submitUpvote = async (reviewId) => {
  const res = await API.post(`review/submitvote/${reviewId}`);
  return res;
};

export const fetchReviewVoted = async (reviewId) => {
  const res = await API.get(`votes/checkReview/${reviewId}`);

  return res;
};

export const showUser = async () => {
  const res = await API.get(`users`);
  return res;
};

export const updateUser = async (inputs) => {
  const res = await API.put(`user/edit`, inputs);
  return res;
};

export const deleteUser = async (userId) => {
  const res = await API.delete(`users/${userId}`);
  return res;
};

export const fetchAvatar = async () => {
  const res = await API.get(`user/avatars`);

  return res;
};

export const updateAvatar = async (inputs) => {
  const res = await API.put(`user/update_avatar`, inputs);
  return res;
};

export const forgotPass = async (inputs) => {
  const res = await API.post(`forgotpass`, inputs);
  return res;
};

export const changePass = async (inputs) => {
  const res = await API.post(`changepass`, inputs);
  return res;
};

export const resubmit = async (inputs) => {
  const res = await API.get(`resubmit/${inputs}`);
  return res;
};