import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Stack } from '@mui/system';
import { Button, FormHelperText, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { register } from '../Utils/fetchUser';

export default function RegisterBox() {
  const Navigate = useNavigate();

  const [error, setError] = React.useState('');
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    alert('You have entered an invalid email address!');
    return false;
  };

  const handleSubmit = async () => {
    if (
      values.username === '' ||
      values.password === '' ||
      values.firstName === '' ||
      values.lastName === '' ||
      values.email === ''
    ) {
      setError('Please Input the all the required field...');
      return;
    }
    const validEmail = validateEmail(values.email);

    if (!validEmail) return;

    try {
      const res = await register(values);
      localStorage.setItem('user', JSON.stringify(res.data));
      Navigate('/');
      window.location.reload();
    } catch (error) {
      setError(error.response.data.error);
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Stack
        direction='column'
        sx={{
          height: { xs: '100vh', md: '80vh' },
          width: { xs: '100vw', md: '40vw' },
          backgroundColor: '#EFEFEF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          border: '1px solid black',
          boxShadow: 5,
        }}
      >
        <Typography
          color='black'
          sx={{
            backgroundColor: '#369BFB',
            width: '100%',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            fontSize: '24px',
            fontFamily: 'Poppins',
            mb: 3,
            top: 0,
            position: 'absolute',
          }}
        >
          Register
        </Typography>

        <Stack direction={{ xs: 'column', md: 'row' }} mt='50px'>
          <TextField
            autoFocus
            label='First Name'
            id='firstName'
            sx={{ m: 1, width: { xs: '35ch', md: '25ch' } }}
            required
            value={values.firstName}
            onChange={(e) =>
              setValues({ ...values, [e.target.id]: e.target.value })
            }
          />

          <TextField
            label='Last Name'
            id='lastName'
            sx={{ m: 1, width: { xs: '35ch', md: '25ch' } }}
            required
            value={values.lastName}
            onChange={(e) =>
              setValues({ ...values, [e.target.id]: e.target.value })
            }
          />
        </Stack>

        <TextField
          label='Username'
          id='username'
          sx={{ m: 1, width: { xs: '35ch', md: '52ch' } }}
          required
          value={values.username}
          onChange={(e) => setValues({ ...values, username: e.target.value })}
        />

        <TextField
          label='Email'
          id='email'
          sx={{ m: 1, width: { xs: '35ch', md: '52ch' } }}
          required
          value={values.email}
          onChange={(e) =>
            setValues({ ...values, [e.target.id]: e.target.value })
          }
        />

        <FormControl
          sx={{ width: { xs: '35ch', md: '52ch' }, mt: 1 }}
          variant='outlined'
        >
          <InputLabel htmlFor='outlined-adornment-password'>
            Password
          </InputLabel>

          <OutlinedInput
            id='password'
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label='Password'
          />
          <FormHelperText
            sx={{
              color: 'red',
              fontSize: '14px',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {error}
          </FormHelperText>
        </FormControl>
        <Button
          variant='contained'
          onClick={handleSubmit}
          sx={{ mt: 1, mb: 3 }}
        >
          register
        </Button>

        <Typography
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            bottom: { xs: '10px', md: '20px' },
          }}
        >
          <Link to='/login' style={{ color: '#2587BB', fontFamily: 'Poppins' }}>
            Sign in
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
