import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Stack, Typography } from '@mui/material';

import Paginate from './Pagination';

const MovieCards = ({ movies, title, total, page }) => {
  const Navigate = useNavigate();

  return (
    <Container sx={{ width: '100%', height: '100%' }}>
      <Typography ml={5} fontFamily='Poppins' fontSize={30}>
        {title}
      </Typography>
      <Stack
        direction='row'
        sx={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 3,
        }}
      >
        {movies.map((movie) => (
          <Paper
            key={movie.id}
            elevation={5}
            sx={{
              m: 1,
              position: 'relative',
              display: 'flex',
              width: { xs: '100px', md: '200px' },
              height: { xs: '200px', md: '300px' },
              backgroundImage: movie.poster_path
                ? `url(https://image.tmdb.org/t/p/original/${movie.poster_path})`
                : 'url(https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              transition: 'ease-in-out 0.4s',
              borderRadius: 1,
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.2)',
                zIndex: '2',
              },
            }}
            onClick={() => {
              Navigate(`/movie/${movie.id}`);
            }}
          ></Paper>
        ))}
      </Stack>
      <Paginate total={total} page={page} />
    </Container>
  );
};

export default MovieCards;
