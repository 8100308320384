import axios from 'axios';
const api_url = process.env.REACT_APP_MOVIEDOCS_API;

// const BASE_URL = 'http://localhost:8080/api/';
let token = JSON.parse(localStorage.getItem('user'))?.jwtToken;

const API = axios.create({
  baseURL: api_url+'/api/',
  withCredentials: true,
  headers: { Authorization: 'Bearer ' + token },
});

export const login = async (inputs) => {
  // const res = await axios.post(`${BASE_URL}login`, inputs);
  const res = await API.post(`login`, inputs);

  return res;
};

export const register = async (inputs) => {
  // const res = await axios.post(`${BASE_URL}register`, inputs);
  const res = await API.post(`register`, inputs);

  return res;
};

export const refreshToken = async () => {
  const res = await API.post(`refresh`);
  return res;
};

export const logout = async () => {
  const res = await API.post(`logout`);
  return res;
};
